/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Button } from '@mui/material';
import Slider from 'configurator/components/atoms/Slider/Slider';
import {
  AdjustBox,
  GridContainer,
  GridWrapper,
  InnerLabel,
  PositionBox,
  SliderWrapper,
  TextVertical,
  UpperBox,
  Wrapper
} from './styled';
import CustomButton from 'components/Button/CustomButton';

type PositionsAdjusterPropsGrips = {
  values: {
    columnPrimary: number[];
    columnSecondary: number[];
  };
  limits: { min: number; max: number }[];
  rows: string[];
  columns: string[];
  handleSliderChange: Function;
  handleOnAfterChange?: Function;
  handleOnBeforeChange?: Function;
  handleInputChange?: Function;
  handleOnAfterChangeInput?: Function;
  graphVersion?: boolean;
  disableInput?: boolean;
  trackClasses?: string[][];
  handleActionButton?: Function;
  buttonText?: string;
};

const PositionsAdjusterGrips = ({
  values,
  limits,
  rows,
  columns,
  handleSliderChange,
  handleOnAfterChange = () => true,
  handleOnBeforeChange = () => true,
  handleInputChange = () => true,
  handleOnAfterChangeInput = () => true,
  graphVersion = false,
  disableInput = false,
  trackClasses = undefined,
  handleActionButton = () => true,
  buttonText = '',
  ...props
}: PositionsAdjusterPropsGrips) => (
  <Wrapper {...props}>
    <GridContainer>
      {values.columnPrimary.map((valuePrimary, index) => (
        <GridWrapper key={rows[index] + index}>
          <TextVertical>
            <h4>{rows[index]}</h4>
          </TextVertical>
          <UpperBox>
            <AdjustBox>
              {graphVersion && index === 0 ? (
                <div>&nbsp;</div>
              ) : (
                <>
                  <InnerLabel>{columns[0]}</InnerLabel>
                  <SliderWrapper>
                    <Slider
                      data={{
                        index,
                        sliderType: 'primary',
                        min: limits[index].min,
                        max: limits[index].max
                      }}
                      handleChange={handleSliderChange}
                      handleOnAfterChange={handleOnAfterChange}
                      handleOnBeforeChange={handleOnBeforeChange}
                      value={values.columnPrimary[index]}
                      min={limits[index].min}
                      max={limits[index].max}
                      disable={disableInput}
                      trackClass={trackClasses ? trackClasses[index][0] : undefined}
                    />
                    <PositionBox
                      type='text'
                      value={values.columnPrimary[index]}
                      min={limits[index].min}
                      max={limits[index].max}
                      onChange={(e) =>
                        handleInputChange(e.target.value, 0, {
                          index,
                          sliderType: 'primary',
                          min: limits[index].min,
                          max: limits[index].max
                        })
                      }
                      onBlur={(e) =>
                        handleOnAfterChangeInput(e.target.value, 0, {
                          index,
                          sliderType: 'primary',
                          min: limits[index].min,
                          max: limits[index].max
                        })
                      }
                      onFocus={handleOnBeforeChange}
                      disabled={disableInput}
                    />
                  </SliderWrapper>
                </>
              )}
            </AdjustBox>
            <AdjustBox>
              <InnerLabel>{columns[1]}</InnerLabel>
              <SliderWrapper>
                <Slider
                  data={{
                    index,
                    sliderType: 'secondary',
                    min: limits[index].min,
                    max: limits[index].max
                  }}
                  handleChange={handleSliderChange}
                  handleOnAfterChange={handleOnAfterChange}
                  handleOnBeforeChange={handleOnBeforeChange}
                  value={values.columnSecondary[index]}
                  min={limits[index].min}
                  max={limits[index].max}
                  disable={disableInput}
                  trackClass={trackClasses ? trackClasses[index][1] : undefined}
                />
                <PositionBox
                  type='text'
                  value={values.columnSecondary[index]}
                  onChange={(e) =>
                    handleInputChange(e.target.value, 0, {
                      index,
                      sliderType: 'secondary',
                      min: limits[index].min,
                      max: limits[index].max
                    })
                  }
                  onBlur={(e) =>
                    handleOnAfterChangeInput(e.target.value, 0, {
                      index,
                      sliderType: 'secondary',
                      min: limits[index].min,
                      max: limits[index].max
                    })
                  }
                  onFocus={handleOnBeforeChange}
                  disabled={disableInput}
                  id='box'
                />
              </SliderWrapper>
            </AdjustBox>
          </UpperBox>
        </GridWrapper>
      ))}
      {!disableInput && (
        <CustomButton color='light' onClick={() => handleActionButton()}>
          {buttonText}
        </CustomButton>
      )}
    </GridContainer>
  </Wrapper>
);

export default PositionsAdjusterGrips;
