/* eslint-disable react/display-name */
import Navbar from 'configurator/components/molecules/Navbar/Navbar';
import React, { memo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Logo from 'assets/aether_logo_white.png';
import { ReactComponent as LogoutSvg } from 'assets/logout-1.svg';
import useLeaveConfigurator from 'configurator/hooks/useLeaveConfigurator';
import { ACCOUNT, CHOOSE_GRIPS } from 'constants/routes';
import CustomButton from 'components/Button/CustomButton';
import { Tooltip, Typography } from '@mui/material';
import useUserData from 'hooks/useUserData';
import { useTranslation } from 'react-i18next';
import CustomAvatar from 'adp-panel/components/CustomAvatar/CustomAvatar';

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: #33499c;
  width: 275px;
  padding: 24px 32px;

  a {
    z-index: 1;
    text-decoration: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Sidebar = memo(() => {
  const { handleLogout } = useLeaveConfigurator();
  const { me } = useUserData();
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar-configurator');

  return (
    <Wrapper>
      <InnerWrapper>
        <NavLink to={CHOOSE_GRIPS} style={{ marginBottom: '36px' }}>
          <img src={Logo} />
        </NavLink>
        <Navbar />
      </InnerWrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title={t('account-settings', 'Account settings')}>
          <div
            onClick={() => navigate(ACCOUNT, { state: { configuratorLayout: true } })}
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <CustomAvatar
              name={me.name}
              style={{
                width: 40,
                height: 40,
                bgcolor: '#FFF',
                fontSize: 16,
                textTransform: 'uppercase',
                marginRight: '16px',
                color: '#475467'
              }}
            />
            <Typography sx={{ color: '#FFF', maxWidth: '100px', wordWrap: 'break-word' }}>
              {me.name}
            </Typography>
          </div>
        </Tooltip>
        <Tooltip title={t('logout', 'Logout')}>
          <div>
            <CustomButton onClick={handleLogout} Icon={LogoutSvg} color='light' />
          </div>
        </Tooltip>
      </div>
    </Wrapper>
  );
});

export default Sidebar;
