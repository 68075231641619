import styled from 'styled-components';

export const ActionButtonsWrapper = styled.td`
  button {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
`;

export const StyledAudioPlayer = styled.audio`
  background-color: white;
  box-shadow: 0px 4px 20px 0px #3d48440d;

  &::-webkit-media-controls-panel {
    background-color: white;
  }
`;

export const ResponseChatWrapper = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: center;
  //align-items: flex-end;
  gap: 8px;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

  &:focus-within {
    outline: 2px solid #33499c;
  }
`;

export const EditableInput = styled.div`
  color: #667085;
  text-overflow: ellipsis;
  font-family: Inter, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;

  &:focus {
    outline: none;
  }

  &:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    color: #667085; /* Optional: color of the placeholder */
    display: block; /* Required to make the placeholder visible */
  }
`;
