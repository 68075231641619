import styled from 'styled-components';
import { TextS } from 'configurator/components/atoms/Typography/Typography';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const TextVertical = styled.div`
  ${TextS};
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-content: center;
  grid-area: vertical;
  margin-bottom: 10px;

  h4 {
    width: 100%;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-gap: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
    grid-gap: 20px;
  }
`;

export const UpperBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  grid-area: box;
`;

export const AdjustBox = styled.div`
  width: 100%;
  background-color: ${({ first }) => (first ? '#FFF' : '#f9fafb')};
  border-radius: 8px;
  padding: 8px 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PositionBox = styled.input<{ onFocus?: any }>`
  font-size: 16px;
  font-family: 'Inter';
  color: #101828;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  text-align: center;
  width: 40px;
  background-color: white;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'vertical vertical'
    'box box';
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InnerLabel = styled.h4`
  text-align: center;
  margin-bottom: 10px;
`;

export const SliderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
`;
