import { Tooltip } from '@mui/material';
import Card from 'adp-panel/components/Card/Card';
import CustomButton from 'components/Button/CustomButton';
import CustomIcon, { icons } from 'components/CustomIcon/CustomIcon';
import { useTranslation } from 'react-i18next';
import { ChartType } from './Filters/GraphDescription';
import {
  GraphComponentContainer,
  GraphComponentInsideContainer,
  GraphWrapper,
  Header,
  OptionsWrapper,
  TotalComponent
} from './styled';
import { PeriodEnum } from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import ConfirmationLoader from '../../layouts/ConfirmationLoader';
import { sumBy } from 'lodash';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import {
  FormState,
  useGraphFormState
} from 'adp-panel/components/DeviceUsageMonitoring/useGraphsFormState';
import {
  DateButtonGroup,
  DatePickerRangeFilter,
  DeviceDropdownFilter,
  ExercisesFilter,
  GripsDropdownFilter,
  InstancesDropdownFilter,
  PeriodDropdownFilter
} from 'adp-panel/components/DeviceUsageMonitoring/Filters/CustomGraphFilters';
import { TransformedExercise } from 'adp-panel/pages/Goals/utils';
import React, { useState } from 'react';
import { GraphDescription } from './Filters/GraphDescription';

dayjs.extend(timezone);
dayjs.extend(utc);

const period_options = [
  { text: 'Day', id: PeriodEnum.day },
  { text: 'Week', id: PeriodEnum.week },
  { text: 'Month', id: PeriodEnum.month }
];

export type PeriodType = (typeof period_options)[number];
export type DateRangeType = { start: Date; end: Date | null };

export interface GraphFilters {
  date?: Date;
  dateFrom?: Date;
  dateTo?: Date;
  instance?: string[];
  period?: PeriodEnum;
  grip?: number | string;
  device?: DeviceEntry;
  dateRange?: DateRangeType;
  exercise?: TransformedExercise;
}

export interface UsageMonitorGraphProps {
  header: string;
  GraphComponent?: any;
  GraphsComponent?: any[];
  renderGraph?: (
    keys: string[],
    data: any,
    yLabel: string | undefined,
    userTimezone: string,
    formState: FormState
  ) => React.ReactNode;
  devices?: DeviceEntry[];
  exercises?: TransformedExercise[];
  initialFilters: any;
  deviceFilter?: boolean;
  dateFilter?: boolean;
  dateRangeFilter?: boolean;
  instancesFilter?: boolean;
  periodFilter?: boolean;
  gripsFilter?: boolean;
  exerciseFilter?: boolean;
  graphDataSource?: any;
  legendMap?: Map<string, string>;
  gripCountText?: Map<string, string>;
  instancesOptions?: { text: string; id: string[] }[];
  graphProps?: any;
  totalCounter?: boolean;
  graphHeight?: string | null;
  additionalFilterProps?: any;
  extraFilters?: any;
  chartType: ChartType;

  onFilterChange(filters: GraphFilters): void;
}

export type InstanceOption = NonNullable<UsageMonitorGraphProps['instancesOptions']>[number];

const UsageMonitoringGraph = (props: UsageMonitorGraphProps) => {
  const { t } = useTranslation();
  const {
    header,
    GraphComponent,
    GraphsComponent,
    renderGraph,
    graphDataSource,
    legendMap,
    gripCountText = new Map<string, string>(),
    deviceFilter = true,
    dateFilter = false,
    dateRangeFilter = false,
    instancesFilter = false,
    periodFilter = false,
    gripsFilter = false,
    exerciseFilter = false,
    graphProps = null,
    totalCounter = true,
    graphHeight = null,
    additionalFilterProps = null,
    extraFilters = null
  } = props;

  // @ts-ignore
  const formState = useGraphFormState(props);
  const userTimezone = formState.device?.amputee?.notifications_timezone ?? dayjs?.tz?.guess();
  const [showDescription, setShowDescription] = useState(false);

  if (!graphDataSource) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <Card>
      <GraphWrapper>
        <Header>
          <div>{header}</div>
          <div>
            <Tooltip
              title={t(
                showDescription
                  ? 'common:device_usage.graph.info_tooltip.hide'
                  : 'common:device_usage.graph.info_tooltip.show',
                {
                  defaultValue: showDescription
                    ? 'Hide graph description'
                    : 'View graph description'
                }
              )}>
              <span>
                <CustomButton
                  Icon={icons['info-circle']}
                  color='light'
                  onClick={() => setShowDescription(!showDescription)}
                />
              </span>
            </Tooltip>
          </div>
        </Header>
        <OptionsWrapper>
          {extraFilters}
          {deviceFilter && (
            <DeviceDropdownFilter
              devices={formState.devices ?? []}
              selectedDevice={formState.device}
              setDevice={formState.setDevice}
            />
          )}
          <DateButtonGroup
            dateRange={formState.dateRange}
            setDate={formState.setDateRange}
            period={formState.period.id}
            setPeriod={formState.setSelectedPeriod}
            additionalProps={additionalFilterProps?.dateRange}
          />
          {dateRangeFilter && (
            <DatePickerRangeFilter
              dateRange={formState.dateRange}
              setDate={formState.setDateRange}
              period={formState.period.id}
              additionalProps={additionalFilterProps?.dateRange}
            />
          )}
          {gripsFilter && (
            <GripsDropdownFilter
              setGrip={formState.setGrip}
              grips={formState.grips}
              selectedGrip={formState.grip}
            />
          )}
          {instancesFilter && (
            <InstancesDropdownFilter
              setInstance={formState.setInstances}
              instances={formState.instances}
              selectedInstance={formState.instance}
            />
          )}
          {periodFilter && (
            <PeriodDropdownFilter
              setPeriod={formState.setPeriod}
              periods={period_options}
              selectedPeriod={formState.period}
            />
          )}
          {exerciseFilter && formState.exercises && formState.exercise && (
            <ExercisesFilter
              setExercise={formState.setExercise}
              exercises={formState.exercises}
              selectedExercise={formState.exercise}
            />
          )}
        </OptionsWrapper>
        <GraphComponentContainer>
          {totalCounter && (
            <TotalComponent>
              {gripCountText.get(formState.instance.text)}:{' '}
              {Math.round(
                sumBy(graphDataSource, (item: any) => parseFloat(item[formState.instance.id[0]]))
              )}
            </TotalComponent>
          )}
          <GraphComponentInsideContainer
            style={
              graphHeight ? { height: graphHeight, maxHeight: graphHeight } : { height: 'inherit' }
            }>
            {renderGraph ? (
              renderGraph(
                formState.instance.id,
                graphDataSource,
                legendMap?.get(formState.instance.text),
                userTimezone,
                formState
              )
            ) : (
              <GraphComponent
                keys={formState.instance.id}
                data={graphDataSource}
                userTimezone={userTimezone}
                yLabel={legendMap?.get(formState.instance.text)}
                exerciseGoalTarget={formState.exercise ?? 0}
                {...graphProps}
              />
            )}
          </GraphComponentInsideContainer>
        </GraphComponentContainer>
        {showDescription && (
          <GraphDescription
            header={header}
            chartType={props.chartType}
            onClose={() => setShowDescription(false)}
          />
        )}
      </GraphWrapper>
    </Card>
  );
};

export default UsageMonitoringGraph;
