/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import DefaultLayout from '../../layouts/DefaultLayout';
import { HeaderComponent } from '../../components/Typography/Header';
import LegendParagraph from '../../components/LegendParagraph/LegendParagraph';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate, useParams } from 'react-router-dom';
import DeviceImg from 'assets/tripod-open.png';
import DeviceRightImg from 'assets/tripod-open-right.png';
import {
  useDeviceConfig,
  useDeviceDelete,
  useDeviceHistory,
  useDeviceInfo
} from '../../hooks/api/useDevices';
import { useUser, useUsers } from '../../hooks/api/useUsers';
import { useModal } from '../../hooks/api/useModal';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../api/users/users.types';
import ConfirmDeleteModal, { ModalMessageDevice } from '../../components/Modals/ConfirmDeleteModal';
import { DetailsLink } from '../../components/Links/Links';
import { SuccessButton } from '../../components/ActionButton/ActionButton';
import ModesGrid from '../Modes/ModesGrid';
import React, { useEffect, useState } from 'react';
import { ModesEntry, ModesExtendOptions, ModesQueryParams } from '../../api/modes/modes.types';
import { useDeviceModes, useUpdateMode } from '../../hooks/api/useModes';
import { CONFIGURATOR_URL } from '../../constants/config';
import DeviceUsageTab from '../DeviceUsage/DeviceUsageTab';
import {
  ButtonsWrapper,
  CompanyDetails,
  DeviceDetailsWrapper,
  DeviceName,
  InnerWrapper,
  LowerGrid,
  RemoveButton,
  StyledCard,
  Subtitle,
  TabsWrapper,
  TooltipWrapper,
  UpperGrid
} from './styled';
import { DeviceExtendOptions, DevicesQueryParams } from '../../api/devices/device.types';
import { userHasPermissions } from '../../utils/permissionUtils';
import * as Sentry from '@sentry/react';
import RepairGrid from './Repair';
import { userHasRole } from '../../hoc/useCanAccess';
import { DEVICE_CONFIG_PERMISSIONS, DEVICES_PERMISSIONS } from '../../constants/rolesPermissions';
import '../Patients/styles.css';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorComponent } from 'adp-panel/components/Error/ErrorComponent';
import useUserData from 'hooks/useUserData';
import { UserRoles } from 'types';
import { CLINICIANS, DEVICE, DEVICE_EDIT, PATIENTS } from 'constants/routes';
import ConfirmationLoader from 'adp-panel/layouts/ConfirmationLoader';
import { useSessionStart } from 'hooks/useSessionStart';

const DeviceDetails = () => {
  const { t } = useTranslation();
  let { deviceId } = useParams() as any;
  deviceId = Number(deviceId);
  const client = useQueryClient();
  const { rolesByName, data: user } = useUserData();
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const isClinicianSupportAccount = userHasRole(user, RoleEnum.clinicianSupport);
  const queryParamsDevicesClinician: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinicians,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion
    ]
  };
  const {
    result: deviceInfo,
    isLoading: isLoadingDeviceInfo,
    isError: isErrorDeviceInfo
  } = useDeviceInfo(
    deviceId,
    userHasPermissions(DEVICES_PERMISSIONS.DETAILS, rolesByName),
    queryParamsDevicesClinician
  );

  const { mutateAsync: deleteDevice, isLoading: isLoadingDelete } = useDeviceDelete();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const handleDelete = async () => {
    await deleteDevice(deviceId);
    navigate(DEVICE);
  };

  const handleEdit = () => {
    navigate(DEVICE_EDIT, { state: { detail: deviceInfo } });
  };

  const { mutateAsync: updateMode } = useUpdateMode();
  const userQueryParams: UsersQueryParams = {
    roles: UserRoles.amputee
  };
  const { result: amputee } = useUsers(userQueryParams);
  const modesQueryParams: ModesQueryParams = {
    extend: ModesExtendOptions.deviceAmputee
  };
  const configModes = useDeviceModes(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName),
    modesQueryParams
  );
  const deviceConfigs = useDeviceConfig(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName)
  );

  const { handleOpenLocalSession } = useSessionStart();

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const submitEditForm = async (formParams: any, currentItem: ModesEntry) => {
    try {
      if (currentItem) {
        // @ts-ignore
        await updateMode({
          name: formParams.mode_name,
          deviceId: currentItem.device_id,
          modeId: currentItem.id
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const toggleDisable = async (mode: ModesEntry) => {
    try {
      if (mode) {
        // @ts-ignore
        await updateMode({
          active: Number(!mode.active),
          deviceId: mode.device_id,
          modeId: mode.id
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const handleEditOnConfigurator = (item: ModesEntry, modeNumber: number) => {
    if (!item) {
      return;
    }
    window.location.replace(`${CONFIGURATOR_URL}/device?deviceId=${deviceId}&modeId=${modeNumber}`);
  };

  const Details = (
    <InnerWrapper>
      <HeaderComponent
        headerText={t('devices:device_details.header.title', 'Device details')}
        isLoading={false}
      />{' '}
      {isModalOpen && (
        <ConfirmDeleteModal
          handleClose={handleModalClose}
          handleAccept={handleDelete}
          isLoading={isLoadingDelete}
          message={<ModalMessageDevice id={deviceId} />}
        />
      )}
      {
        <StyledCard>
          <UpperGrid>
            <DeviceDetailsWrapper>
              <img
                src={deviceInfo?.model?.orientation === 'left' ? DeviceImg : DeviceRightImg}
                alt={t('devices:device_details.image.alt', 'Device image')}
              />
              <div>
                <DeviceName>{deviceInfo?.serial}</DeviceName>
                <Subtitle>{deviceInfo?.model?.name}</Subtitle>
              </div>
            </DeviceDetailsWrapper>
            <ButtonsWrapper>
              {userHasPermissions(DEVICES_PERMISSIONS.CONFIGURE, rolesByName) && (
                <SuccessButton
                  data-testid='configurator'
                  onClick={() =>
                    handleOpenLocalSession(deviceInfo?.serial, deviceInfo?.bluetooth_id, deviceId)
                  }
                  icon='gear'>
                  {t('devices:device_details.buttons.configurator', 'Configurator')}
                </SuccessButton>
              )}
            </ButtonsWrapper>
          </UpperGrid>
          <LowerGrid>
            <LegendParagraph
              headerText={t('devices:device_details.tabs.clinician_paragraph', 'CLINICIAN')}>
              <CompanyDetails>
                <DetailsLink
                  disabled={isClinicianSupportAccount}
                  to={`${CLINICIANS}/${deviceInfo?.clinician?.id}`}>
                  {deviceInfo?.clinician?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            <LegendParagraph
              headerText={t('devices:device_details.tabs.patient_paragraph', 'PATIENT')}>
              <CompanyDetails>
                <DetailsLink to={`${PATIENTS}/${deviceInfo?.amputee_id}`}>
                  {deviceInfo?.amputee?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
          </LowerGrid>
        </StyledCard>
      }
    </InnerWrapper>
  );

  if (isErrorDeviceInfo)
    return (
      <ErrorComponent
        message={t(
          'devices:device_details.errors.device_not_found',
          "The device no longer exists or you don't have access to it."
        )}
        allowRedirect
      />
    );

  return <DefaultLayout>{isLoadingDeviceInfo ? <ConfirmationLoader /> : Details}</DefaultLayout>;
};

export default DeviceDetails;
