export const BLOCK_MODALS = {
  DEMO_CHANGE: 'DEMO_CHANGE',
  CHANGING_CONFIG_STATE: 'CHANGING_CONFIG_STATE',
  SEND_CONFIG_API: 'SEND_CONFIG_API',
  CONNECT: 'CONNECT',
  CONNECT_REGISTER: 'CONNECT_REGISTER',
  SEND_ALL_CONFIG: 'SEND_ALL_CONFIG',
  UNSAVED_CHANGES: 'UNSAVED_CHANGES',
  MOBILE_BLOCK: 'MOBILE_BLOCK',
  PAIRING: 'PAIRING',
  PROCEDURE: 'PROCEDURE'
};
