import { Typography, TablePaginationProps, Chip, Box } from '@mui/material';
import {
  DataGrid,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  DataGridProps,
  GridColDef,
  GridPaginationModel,
  GridCallbackDetails
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as NextSvg } from 'assets/next-pagination.svg';
import { ReactComponent as PreviousSvg } from 'assets/previous-pagination.svg';
import { useMemo, useRef } from 'react';
import CustomButton from 'components/Button/CustomButton';
import CustomChip from 'components/CustomChip/CustomChip';

const DataGridStyles = {
  backgroundColor: 'white',
  minHeight: '400px',
  '&.MuiDataGrid-root': {
    borderRadius: '8px',
    overflow: 'hidden',
    minHeight: 'unset'
  },
  '.MuiDataGrid-footerContainer': {
    justifyContent: 'center'
  },
  '.MuiDataGrid-row:nth-child(2n)': {
    backgroundColor: '#F9FAFB'
  },
  '.MuiDataGrid-cell': {
    outline: 'none !important'
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#F9FAFB',
    pointerEvents: 'none',
    cursor: 'default'
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none'
  }
};

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  height: 100%;
`;

export const TableHeaderWrapper = styled.div`
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function Pagination({
  page,
  onPageChange,
  className
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color='lightPagination'
      className={className}
      count={pageCount}
      page={page + 1}
      boundaryCount={3}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
      shape='rounded'
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: (props) => <CustomButton {...props} Icon={PreviousSvg} color='light' />,
            next: (props) => <CustomButton {...props} Icon={NextSvg} color='light' />
          }}
          {...item}
        />
      )}
    />
  );
}

function CustomPagination(props: any) {
  return (
    <GridPagination
      ActionsComponent={Pagination}
      labelRowsPerPage={''}
      sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
      {...props}
    />
  );
}

interface CustomTableProps extends React.ComponentPropsWithoutRef<'div'> {
  tableData: any;
  mapTableData: any;
  columns: readonly GridColDef<any>[];
  tableHeader?: string;
  isLoading: boolean;
  totalItems: number;
  paginationModel: GridPaginationModel | undefined;
  sx?: any;
  actions?: React.ReactNode;
  counter?: boolean;
  setPaginationModel:
    | ((model: GridPaginationModel, details: GridCallbackDetails) => void)
    | undefined;
}

export const TableHeader = ({
  tableHeader,
  actions,
  count
}: {
  tableHeader: string;
  actions?: React.ReactNode;
  count?: number;
}) => (
  <TableHeaderWrapper>
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <Typography variant='h6'>{tableHeader}</Typography>
      {count && <CustomChip color='info' label={count} size='small' />}
    </div>
    {actions}
  </TableHeaderWrapper>
);

const CustomTable = ({
  tableData,
  mapTableData,
  columns,
  tableHeader,
  totalItems,
  isLoading,
  paginationModel,
  setPaginationModel,
  sx,
  actions,
  counter,
  ...props
}: CustomTableProps & DataGridProps) => {
  const { t } = useTranslation();
  const rowCountRef = useRef(totalItems || 0);

  const rowCount = useMemo(() => {
    if (totalItems !== undefined) {
      rowCountRef.current = totalItems;
    }
    return rowCountRef.current;
  }, [totalItems]);

  return (
    <>
      {tableHeader && (
        <TableHeader
          actions={actions}
          tableHeader={tableHeader}
          count={counter && totalItems ? totalItems : undefined}
        />
      )}
      <DataGrid
        {...props}
        autoHeight
        disableColumnMenu
        disableColumnResize
        disableColumnFilter
        disableColumnSorting
        disableRowSelectionOnClick
        rows={tableData ? mapTableData(tableData) : []}
        slots={{ pagination: CustomPagination }}
        localeText={{
          noRowsLabel: t('common:date_grid.no_rows_label', 'No rows'),
          noResultsOverlayLabel: t('common:date_grid.no_results_overlay_abel', 'No results found.')
        }}
        columns={columns}
        sortingMode='server'
        rowCount={rowCount}
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        sx={{ ...sx, ...DataGridStyles }}
      />
    </>
  );
};

export default CustomTable;
