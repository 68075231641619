import { useTypedParams } from 'hooks/useTypedParams';
import GoalUsageTab from '../Goals/GoalsUsageTab';
import CustomButton from 'components/Button/CustomButton';
import { MODALS } from 'configurator/views/Modals';
import { useUiStore } from 'configurator/reducers/uiStore';

const PatientGoals = () => {
  const { patientId } = useTypedParams(['patientId']);

  const { openModal } = useUiStore((state: any) => ({
    openModal: state.openModal
  }));

  const openAddGoalModal = () => {
    openModal(MODALS.addGoalModal, {
      patientId: patientId
    });
  };

  return (
    <div style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important' }}>
      <CustomButton
        sx={{ margin: '20px 0' }}
        color='light'
        data-testid='button-delete'
        onClick={() => openAddGoalModal()}>
        Add new goal
      </CustomButton>
      <GoalUsageTab userId={parseInt(patientId, 10)} />
    </div>
  );
};

export default PatientGoals;
