import styled from 'styled-components';
import BackgroundRegister from 'assets/girl_in_the_hallway.png';

export const ForgottenPasswordLink = styled.a`
  color: #253786;
  font-size: ${({ theme }) => theme.fontSize.labelBig};
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const Auth2FAWrapper = styled.div`
  width: 630px;
  padding: 30px;
`;

export const Description2FA = styled.p`
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 2}px;
  color: ${({ theme }) => theme.palette.headerTitle};
  font-size: ${({ theme }) => theme.fontSize.title};
`;

export const Line = styled.span`
  width: 100%;
  background-color: #dee2e6;
  height: 1px;
  margin: 30px 0;
  display: block;
`;

export const SmallParagraph = styled.p`
  font-size: 14px;
  text-align: center;
  color: #626c84;
`;

export const RegisterWrapper = styled.div`
  background-image: url(${BackgroundRegister});
  background-size: cover;
`;

export const RegisterBackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(54, 66, 106, 0.85);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RegisterHeader = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: white;
`;

export const RegisterParagraph = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: white;
  line-height: 28px;
`;

export const FormWrapper = styled.div`
  display: grid;
  gap: 16px;
`;

export const Logo = styled.img`
  height: 48px;
  margin-bottom: 20px;
  object-fit: contain;
  justify-self: center;
`;

export const FooterWrapperLogout = styled.div`
  align-self: flex-end;
  justify-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const CountDownTimer = styled.span`
  font-weight: 500;
  color: #adb5bd;
`;

export const BottomLoginSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
