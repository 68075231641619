import { Tab, Tabs } from '@mui/material';
import Card from 'adp-panel/components/Card/Card';
import {
  GripCountGraph,
  GripCountGraphPie
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs/GripCountGraph';
import { mapGripTimeData } from 'adp-panel/components/DeviceUsageMonitoring/Mappers/GripTimeDataMapper';
import { TabsContainer } from 'adp-panel/pages/Patients/styled/PatientTabs.styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartsWrapper } from '../Patients/styled';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import {
  EmgPeakChartQueryParams,
  EmgPeakPeriodEnum,
  GroupEnum,
  PeriodEnum,
  TimeSpentChartQueryParams,
  UsageMonitoryQueryParams,
  VelocityLevelChartQueryParams,
  VelocityLevelGroupEnum
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import UsageMonitoringGraph, {
  GraphFilters
} from '../../components/DeviceUsageMonitoring/UsageMonitoringGraph';
import {
  EmgPeakGraphNew,
  GripBreakdownGraphHour,
  GripCountHourlyGraph,
  TotalGripBreakdownGraph
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs';
import { gripsGroupsOptionsMap } from 'adp-panel/utils/definesLocal';
import useDeviceUsageTab, {
  filterParser,
  formatData
} from 'adp-panel/pages/DeviceUsage/useDeviceUsageTab';
import { FeatureToggle } from 'AppProviders';
import {
  DUM_EMG_PEAK_PLOT_WEB,
  DUM_TIME_SPENT_PLOT_WEB,
  DUM_VELOCITY_LEVELS_PLOT_WEB
} from 'adp-panel/constants/featureToggles';
import { useFeatureToggleIsEnabled } from 'adp-panel/hooks/useFeatureToggleIsEnabled';
import {
  useDeviceUsageChartData,
  useDeviceUsageGripsCountData,
  useDeviceUsageTimeSpentData,
  useEmgPeakChartData,
  useVelocityLevelChatData
} from 'adp-panel/hooks/api/useDeviceUseageMonitoring';
import fillDataGaps from 'adp-panel/components/DeviceUsageMonitoring/Mappers/ChartDataMapper';
import {
  VelocityLevelByHourGraph,
  VelocityLevelCloseGraph,
  VelocityLevelOpenGraph
} from 'adp-panel/components/DeviceUsageMonitoring/Graphs/VelocityLevelGraph';
import velocityLevelMapperByHours, {
  velocityLevelCloseMapper,
  velocityLevelOpenMapper
} from 'adp-panel/components/DeviceUsageMonitoring/Mappers/VelocityLevelDataMapper';
import { OneColumnGraphLayout, TwoColumnGraphLayout } from 'adp-panel/pages/DeviceUsage/styled';
import { TimeSpentGraphWithCustomLegend } from '../../components/DeviceUsageMonitoring/Graphs/TimeSpentGraph';
import dayjs from 'dayjs';
import { CustomTabPanel } from 'components/TabPanel/CustomTabPanel';

type GripUsageData = {
  group_by: number;
  instances: number;
  time: number;
  percentage: string;
  'Grip count': number;
  'Grip switches': number;
  'Time spent': number;
  Percentage: string;
  label_name: string;
};

const mapTotalGripData = (items: any) => {
  const baseData = Array.from(gripsGroupsOptionsMap.entries()).map(
    ([gripId, gripName]): GripUsageData => ({
      group_by: gripId,
      instances: 0,
      time: 0,
      percentage: '0.00',
      'Grip count': 0,
      'Grip switches': 0,
      'Time spent': 0,
      Percentage: '0.00',
      label_name: gripName
    })
  );

  if (!items?.length) {
    return baseData;
  }

  const existingDataMap = new Map<number, GripUsageData>(
    items.map((item: GripUsageData) => [Number(item.group_by), item])
  );

  return baseData.map((baseItem) => {
    const existingItem = existingDataMap.get(baseItem.group_by);
    if (!existingItem) return baseItem;

    return {
      ...baseItem,
      instances: existingItem.instances || 0,
      time: existingItem.time || 0,
      percentage: existingItem.percentage || '0.00',
      'Grip count': existingItem.instances || 0,
      'Grip switches': existingItem.instances || 0,
      'Time spent': existingItem.time ? Math.floor(existingItem.time / 60) : 0,
      Percentage: existingItem.percentage ? parseFloat(existingItem.percentage).toFixed(2) : '0.00'
    };
  });
};

const mapTimeSpentData = (timeSpentData: any) => {
  return [
    {
      id: 'Active Time',
      label: 'Active Time',
      color: 'hsl(219, 96%, 57%)',
      Time: timeSpentData?.active_time ? timeSpentData?.active_time : 0,
      Percentage: parseFloat(timeSpentData?.percentage_active_time).toFixed(2),
      description:
        'Periods where EMG signals are produced, indicating muscle activity. If signals are detected within 300 seconds of each other, the system continuously counts this as active time, allowing for brief pauses without resetting the activity count.'
    },
    {
      id: 'Passive Time',
      label: 'Passive Time',
      color: 'hsl(210, 10%, 91%)',
      Time: timeSpentData?.passive_time ? timeSpentData?.passive_time : 0,
      Percentage: parseFloat(timeSpentData?.percentage_passive_time).toFixed(2),
      description:
        "When no EMG signals are detected for a duration exceeding the 300-second threshold, it's marked as inactive time. Brief, isolated signals after long pauses require confirmation (multiple signals) to be considered active again."
    }
  ];
};

const mapHourlyGripData = (items: any) => {
  const hourlyReport: any = [];
  const itemsByHour = items.reduce((prev: any, next: any) => {
    if (prev[Number(next['group_by'])] !== undefined) {
      const prevElement = prev[Number(next['group_by'])];
      prev[parseInt(next['group_by'])] = {
        ...next,
        goal: parseInt(next?.goal) + parseInt(prevElement?.goal),
        instances: parseInt(next.instances) + parseInt(prevElement.instances),
        time: parseInt(next.time + prev.time)
      };
    } else {
      prev[parseInt(next['group_by'])] = next;
    }
    return prev;
  }, []);

  for (let i = 0; i <= 23; i++) {
    const item = itemsByHour[i];
    if (item !== undefined) {
      hourlyReport.push({
        ...item,
        group_by: `${String(item.group_by).padStart(2, '0')}-${String(
          Number(item.group_by) + 1
        ).padStart(2, '0')}`
      });
    } else {
      hourlyReport.push({
        group_by: `${String(i).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`,
        time: 0,
        instances: 0,
        percentage: 0,
        grip: null
      });
    }
  }

  return mapTotalGripData(hourlyReport);
};

const legendMap = new Map<string, string>([
  ['Instance', 'Number of grip switches performed'],
  ['Percentage', 'Percentage of grip switches [%]'],
  ['Time', 'Time spent in a grip [min]']
]);

const legendMapForGripCountText = new Map<string, string>([
  ['Instance', 'Total grip count'],
  ['Percentage', 'Percentage count [%]'],
  ['Time', 'Total minutes spent in all grips']
]);

const legendMapCount = new Map<string, string>([
  ['Instance', 'Number of grips performed'],
  ['Percentage', 'Percentage of grips performed [%]'],
  ['Time', '']
]);

const DeviceUsageTab = ({ devices }: { devices: DeviceEntry[] }) => {
  const timeSpentGraphIsEnabled = useFeatureToggleIsEnabled(DUM_TIME_SPENT_PLOT_WEB);
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);

  const { chartData: velocityLevelByHours, handleFilterChange: handleVelocityLevelByHoursFilter } =
    useDeviceUsageTab<VelocityLevelChartQueryParams>(
      useVelocityLevelChatData,
      {
        date_from: formatData(new Date()),
        group: VelocityLevelGroupEnum.hourly
      },
      filterParser.velocity
    );

  const { chartData: velocityLevel, handleFilterChange: handleVelocityLevelFilter } =
    useDeviceUsageTab<VelocityLevelChartQueryParams>(
      useVelocityLevelChatData,
      {
        date_from: formatData(new Date()),
        group: VelocityLevelGroupEnum.none
      },
      filterParser.velocity
    );

  const { chartData: totalGripCountData, handleFilterChange: handleTotalGripCountFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageGripsCountData);

  const { chartData: hourlyGripData, handleFilterChange: handleHourlyGripFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(
      useDeviceUsageChartData,
      {
        group: GroupEnum.hourly
      },
      filterParser.hourly
    );

  const { chartData: hourlyGripCountData, handleFilterChange: handleHourlyGripCountFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(
      useDeviceUsageGripsCountData,
      {
        group: GroupEnum.hourly
      },
      filterParser.hourly
    );

  const { chartData: totalGripData, handleFilterChange: handleTotalGripFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageChartData);

  const { chartData: totalGripTimeData, handleFilterChange: handleTotalGripTimeFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageChartData);

  //EMG peaks
  const { chartData: emgPeaksData, handleFilterChange: handleEmgPeakFilter } =
    useDeviceUsageTab<EmgPeakChartQueryParams>(
      useEmgPeakChartData,
      { date_from: formatData(new Date()), period: EmgPeakPeriodEnum.all },
      filterParser.emgPeak
    );

  //Time spent
  const { chartData: timeSpentData, handleFilterChange: handleTimeSpentFilter } =
    useDeviceUsageTab<TimeSpentChartQueryParams>(useDeviceUsageTimeSpentData);

  const handleVelocityFilter = (filters: GraphFilters) => {
    handleVelocityLevelByHoursFilter(filters);
    handleVelocityLevelFilter(filters);
  };

  const handleSelect = (_: any, tabIndex: any) => {
    setSelected(tabIndex);
  };

  const todayDate = dayjs();
  return (
    <ChartsWrapper>
      <UsageMonitoringGraph
        header='Active and passive time plot'
        chartType='active-passive-time'
        totalCounter={false}
        devices={devices ?? []}
        initialFilters={{
          instance: 'Time',
          dateRange: {
            start: todayDate.subtract(30, 'day').toDate(),
            end: todayDate.subtract(1, 'day').toDate()
          }
        }}
        instancesOptions={[
          { text: 'Time', id: ['Time'] },
          { text: 'Percentage', id: ['Percentage'] }
        ]}
        onFilterChange={handleTimeSpentFilter}
        instancesFilter
        additionalFilterProps={{
          dateRange: {
            max: todayDate.toDate()
          }
        }}
        legendMap={
          new Map<string, string>([
            ['Time', ' Hours'],
            ['Percentage', '%']
          ])
        }
        graphDataSource={mapTimeSpentData(timeSpentData) ?? []}
        graphHeight='400px'
        GraphComponent={TimeSpentGraphWithCustomLegend}
      />
      <UsageMonitoringGraph
        header='Grip count'
        chartType='grip-count'
        gripCountText={legendMapForGripCountText}
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day
        }}
        instancesOptions={[
          { text: 'Instance', id: ['Grip count'] },
          { text: 'Percentage', id: ['Percentage'] }
        ]}
        onFilterChange={handleTotalGripCountFilter}
        instancesFilter
        legendMap={legendMapCount}
        graphDataSource={mapTotalGripData(totalGripCountData ?? [])}
        // GraphComponent={GripCountGraph.tsx}
        renderGraph={(keys, data, yLabel, userTimezone, formState) => {
          if (formState.selectedPeriod === 'today') {
            return (
              <GripCountGraphPie
                data={mapGripTimeData(totalGripCountData ?? [])}
                yLabel={yLabel}
                userTimezone={userTimezone}
                keys={keys}
              />
            );
          }
          if (formState.date)
            return (
              <GripCountGraph
                data={mapTotalGripData(totalGripCountData ?? [])}
                yLabel={yLabel}
                userTimezone={userTimezone}
                keys={keys}
              />
            );
        }}
        graphHeight='400px'
      />
      <FeatureToggle name={DUM_VELOCITY_LEVELS_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Velocity'
          chartType='velocity'
          devices={devices ?? []}
          totalCounter={false}
          graphHeight={'1000px'}
          initialFilters={{
            grip: { text: 'All', id: 'all' },
            instance: 'Velocity',
            group: VelocityLevelGroupEnum.hourly
          }}
          instancesOptions={[{ text: 'Velocity', id: ['Speed 1', 'Speed 2', 'Speed 3'] }]}
          onFilterChange={handleVelocityFilter}
          legendMap={legendMapCount}
          gripsFilter
          graphDataSource={velocityLevelMapperByHours(velocityLevelByHours ?? [])}
          renderGraph={(keys, data, yLabel, userTimezone) => {
            return (
              <>
                <Card>
                  <TabsContainer>
                    <Tabs value={selected} onChange={handleSelect}>
                      <Tab
                        label={t('common:component.graph.graph_tab.velocity_open', 'Open')}
                        id='velocity-tabp-0'
                        aria-controls='velocity-tabpanel-0'
                      />

                      <Tab
                        label={t('common:component.graph.graph_tab.velocity_close', 'Close')}
                        id='velocity-tabp-1'
                        aria-controls='velocity-tabpanel-1'
                      />
                    </Tabs>
                    <CustomTabPanel value={selected} index={0}>
                      <OneColumnGraphLayout>
                        <VelocityLevelByHourGraph
                          keys={keys}
                          yLabel={yLabel}
                          data={velocityLevelMapperByHours(velocityLevelByHours ?? []).open}
                          userTimezone={userTimezone}
                        />
                      </OneColumnGraphLayout>
                    </CustomTabPanel>
                    <CustomTabPanel value={selected} index={1}>
                      <OneColumnGraphLayout>
                        <VelocityLevelByHourGraph
                          keys={keys}
                          yLabel={yLabel}
                          userTimezone={userTimezone}
                          data={velocityLevelMapperByHours(velocityLevelByHours ?? []).close}
                        />
                      </OneColumnGraphLayout>
                    </CustomTabPanel>
                  </TabsContainer>
                </Card>
                <TwoColumnGraphLayout>
                  <VelocityLevelOpenGraph
                    yLabel={yLabel}
                    userTimezone={userTimezone}
                    keys={keys}
                    data={velocityLevelOpenMapper(velocityLevel ?? [])}
                  />
                  <VelocityLevelCloseGraph
                    keys={keys}
                    userTimezone={userTimezone}
                    data={velocityLevelCloseMapper(velocityLevel ?? [])}
                  />
                </TwoColumnGraphLayout>
              </>
            );
          }}
          GraphComponent={VelocityLevelByHourGraph}
        />
      </FeatureToggle>
      <UsageMonitoringGraph
        header='Grip count by hour'
        chartType='grip-count-hour'
        gripCountText={legendMapForGripCountText}
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day,
          grip: { text: 'All', id: 'all' }
        }}
        instancesOptions={[{ text: 'Instance', id: ['Grip count'] }]}
        onFilterChange={handleHourlyGripCountFilter}
        gripsFilter
        legendMap={legendMapCount}
        graphHeight='400px'
        graphDataSource={mapHourlyGripData(hourlyGripCountData ?? [])}
        GraphComponent={GripCountHourlyGraph}
      />
      <UsageMonitoringGraph
        header='Grip switching count'
        chartType='grip-switching'
        gripCountText={
          new Map<string, string>([
            ['Instance', 'Total grip switching count'],
            ['Percentage', 'Percentage count [%]'],
            ['Time', 'Total minutes spent in all grips']
          ])
        }
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day
        }}
        {...(timeSpentGraphIsEnabled && {
          instancesOptions: [
            { text: 'Instance', id: ['Grip switches'] },
            { text: 'Percentage', id: ['Percentage'] }
          ]
        })}
        onFilterChange={handleTotalGripFilter}
        instancesFilter
        graphHeight='400px'
        legendMap={legendMap}
        graphDataSource={mapTotalGripData(totalGripData ?? [])}
        GraphComponent={TotalGripBreakdownGraph}
      />
      <FeatureToggle name={DUM_TIME_SPENT_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Time spent in a grip'
          chartType='time-spent'
          gripCountText={
            new Map<string, string>([
              ['Instance', 'Total minutes spent in all grips'],
              ['Percentage', 'Total minutes spent in all grips'],
              ['Time', 'Total minutes spent in all grips']
            ])
          }
          devices={devices ?? []}
          initialFilters={{
            instance: 'Time',
            period: PeriodEnum.month
          }}
          onFilterChange={handleTotalGripTimeFilter}
          graphHeight='400px'
          legendMap={new Map<string, string>([['Time', 'Time [min]']])}
          graphDataSource={mapTotalGripData(totalGripTimeData ?? [])}
          GraphComponent={TotalGripBreakdownGraph}
        />
      </FeatureToggle>
      <UsageMonitoringGraph
        header='Grip switching breakdown by hour'
        chartType='grip-switching-hour'
        gripCountText={
          new Map<string, string>([
            ['Instance', 'Total grip switching count'],
            ['Percentage', 'Percentage count [%]'],
            ['Time', 'Total minutes spent in all grips']
          ])
        }
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day,
          grip: { text: 'All', id: 'all' }
        }}
        instancesOptions={[{ text: 'Instance', id: ['Grip switches'] }]}
        onFilterChange={handleHourlyGripFilter}
        gripsFilter
        legendMap={legendMap}
        graphHeight='400px'
        graphDataSource={mapHourlyGripData(hourlyGripData ?? [])}
        GraphComponent={GripBreakdownGraphHour}
      />
      <FeatureToggle name={DUM_EMG_PEAK_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Activity density'
          chartType='activity-density'
          gripCountText={new Map<string, string>([['Instance', 'EMG peaks']])}
          legendMap={new Map<string, string>([['EMG', 'Peak value']])}
          devices={devices ?? []}
          initialFilters={{
            instance: 'Instance',
            period: PeriodEnum.month,
            grip: { text: 'All', id: 'all' }
          }}
          instancesOptions={[{ text: 'Instance', id: ['count'] }]}
          graphHeight='400px'
          onFilterChange={handleEmgPeakFilter}
          graphDataSource={fillDataGaps(emgPeaksData ?? [])}
          GraphComponent={EmgPeakGraphNew}
        />
      </FeatureToggle>
    </ChartsWrapper>
  );
};

export default DeviceUsageTab;
