import AuthLayout from 'adp-panel/layouts/AuthLayout';
import { useTranslation } from 'react-i18next';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { FormWrapper, RegisterWrapper } from '../Login/styled';
import { useState } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { SectionHeader, SectionParagraph } from './styled';
import * as yup from 'yup';
import { emailSchema } from 'configurator/views/Register/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { VALIDATOR_TEXT } from 'constants/validatorText';
import { isStrongPassword } from 'configurator/views/Register/utils';
import { CodeWrapper } from 'configurator/views/Register/styled';
import CustomVerificationCode from 'adp-panel/components/CustomVerificationCode/CustomVerificationCode';
import CustomButton from 'components/Button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { LOGIN, REGISTER_USER_2FA } from '../../../../constants/routes';
import BasicInfo from './BasicInfo';
import EmailSection from './EmailSection';
import { languages } from 'components/FormFields/CustomLanguageSelect';
import ClinicsInfo from './ClinicsInfo';
import { phoneValidatorRequired } from 'adp-panel/components/FormInput/validators';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { useRegisterNewUser } from 'hooks/useRegisterNewUser';
import * as Sentry from '@sentry/react';

import { useAuthentication } from 'hooks/useAuthentication';

const RegisterUser = () => {
  const { t } = useTranslation('auth');
  const { isLoading, handleSubmit } = useRegisterNewUser();
  const { login } = useAuthentication(true);

  const FORM_STEPS = [
    {
      id: 0,
      label: t('auth:register_form.step0.label', 'Basic Info'),
      fieldsToValidated: [
        'name',
        'phone',
        'email',
        'password',
        'rePassword',
        'language',
        'termsOfService'
      ]
    },
    {
      id: 1,
      label: t('auth:register_form.step1.label', 'Activation Code'),
      fieldsToValidated: ['code']
    },
    {
      id: 2,
      label: t('auth:register_form.step2.label', 'Clinicic`s Info'),
      fieldsToValidated: []
    }
  ];

  const [formStep, setFormStep] = useState(FORM_STEPS[0].id);

  const handleNext = async (names) => {
    const isStepValid = names ? await trigger(names) : true;
    if (isStepValid) setFormStep((prevActiveStep) => prevActiveStep + 1);
  };

  const setStep = (id: number) => {
    if (id > formStep) return;
    else setFormStep(id);
  };

  const onSubmit = async (data: any) => {
    const result = {
      name: data.name,
      email: data.email,
      password: data.password,
      phone: data.phone,
      language: data.language.code,
      clinic_name: data.clinicName,
      clinic_location: data.clinicLocation,
      address1: data.clinicAddress,
      address2: data.clinicAddress2,
      activation_code: data.code,
      mfa_enabled: false
    };

    try {
      const newUser = await handleSubmit(result);
      if (newUser.id) {
        await login({ email: newUser.email, password: data.password, blockRedirect: true });
        localStorage.setItem('redirectUrl', REGISTER_USER_2FA);
        navigate(REGISTER_USER_2FA);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const isPhoneValid = (phoneNumber) => {
    return isValidPhoneNumber(phoneNumber) && phoneValidatorRequired(phoneNumber).length === 0;
  };

  const registerUserSchema = yup.object().shape({
    name: yup.string().required(t('auth:component.register.name.validator', 'Name is required')),
    code: yup.string().required(t('auth:component.register.code.validator', 'Code is required')),
    email: emailSchema.required(
      t('auth:component.register.email.validator', 'Email must be valid string')
    ),
    phone: yup
      .string()
      .required(t('auth:component.register.phone.validator', 'Phone is required'))
      .test('is-phone-valid', 'Phone number is not valid', (value) => {
        return isPhoneValid(value);
      }),
    password: yup
      .string()
      .required(VALIDATOR_TEXT.REQUIRED)
      .test('valid-pass', VALIDATOR_TEXT.PASSWORD_NOT_VALID, (value) => {
        if (value === undefined || value === null || value === '') return true;

        return isStrongPassword(value);
      })
      .nullable(),
    rePassword: yup
      .string()
      .required(VALIDATOR_TEXT.REQUIRED)
      .test('passwords-match', VALIDATOR_TEXT.PASSWORDS_MUST_MATCH, function (value) {
        const parentPassword = this.parent.password;
        if (parentPassword === undefined || parentPassword === null || parentPassword === '')
          return true;

        return parentPassword === value;
      }),
    language: yup
      .object({
        code: yup.string().typeError(VALIDATOR_TEXT.REQUIRED).oneOf(['pl', 'en'])
      })
      .nonNullable(VALIDATOR_TEXT.REQUIRED),
    termsOfService: yup
      .boolean()
      .oneOf(
        [true],
        t('auth:component.register.termsOfService.checkbox', 'You must accept the terms of service')
      )
      .required(
        t('auth:component.register.termsOfService.checkbox', 'You must accept the terms of service')
      )
  });

  interface FormValues {
    name: string;
    phone: string;
    email: string;
    password: string | null;
    rePassword: string;
    language: {
      code?: string;
    };
    code: string;
    termsOfService: boolean;
    mfaEnabled?: boolean;
  }

  const {
    handleSubmit: handleSubmitRegister,
    control,
    trigger,
    formState: { errors },
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(registerUserSchema),
    mode: 'onChange',
    defaultValues: {
      language: languages[0],
      password: ''
    }
  });

  const redirection = LOGIN;
  const navigate = useNavigate();

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
      <div>
        <AuthLayout lightVariant noFooter>
          <AuthWrapperBox>
            <AuthTitle>{t('auth:component.register.title', 'Create account')}</AuthTitle>
            <AuthParagraph variant='body2'>
              {t(
                'auth:component.register.paragraph',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
              )}
            </AuthParagraph>
            <form onSubmit={handleSubmitRegister(onSubmit)}>
              <Stepper alternativeLabel activeStep={formStep} sx={{ marginBottom: '25px' }}>
                {FORM_STEPS.map((step) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  return (
                    <Step key={step.label} {...stepProps} onClick={() => setStep(step.id)}>
                      <StepLabel
                        sx={{ '& .MuiStepLabel-label': { fontSize: '16px' } }}
                        {...labelProps}>
                        {step.label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <FormWrapper>
                <div
                  style={{ display: formStep === FORM_STEPS[0].id ? 'grid' : 'none', gap: '5px' }}>
                  <BasicInfo control={control} watch={watch} />
                </div>
                <div
                  style={{ display: formStep === FORM_STEPS[1].id ? 'grid' : 'none', gap: '5px' }}>
                  <SectionHeader>
                    {t('auth:component.register.subtitle_activation_code', 'Activation code')}
                  </SectionHeader>
                  <SectionParagraph>
                    {t(
                      'auth:component.register.text_activation_code',
                      'Please enter the activation code included with the product.'
                    )}
                  </SectionParagraph>
                  <CodeWrapper>
                    <Controller
                      name='code'
                      control={control}
                      render={({ field }) => (
                        <CustomVerificationCode
                          {...field}
                          onChange={field.onChange}
                          value={field.value}
                        />
                      )}
                    />
                    {errors.code && <span style={{ color: '#d32f2f' }}>{errors.code.message}</span>}
                  </CodeWrapper>
                </div>
                <div
                  style={{ display: formStep === FORM_STEPS[2].id ? 'grid' : 'none', gap: '5px' }}>
                  <ClinicsInfo control={control} />
                </div>
                <FormButtonsWrapper>
                  <CustomButton type='button' color='light' onClick={() => navigate(redirection)}>
                    {t('auth:component.register_details.button.cancel', 'Cancel')}
                  </CustomButton>
                  {formStep === FORM_STEPS[2].id ? (
                    <LoadingButton type='submit' loading={isLoading}>
                      {t('auth:register.button.submit', 'Submit')}
                    </LoadingButton>
                  ) : (
                    <CustomButton
                      type='button'
                      onClick={() => handleNext(FORM_STEPS[formStep].fieldsToValidated)}>
                      {t('auth:register.button.next_step', 'Next step')}
                    </CustomButton>
                  )}
                </FormButtonsWrapper>
              </FormWrapper>
              {formStep == FORM_STEPS[1].id && <EmailSection />}
            </form>
          </AuthWrapperBox>
        </AuthLayout>
      </div>
      <RegisterWrapper />
    </div>
  );
};

export default RegisterUser;
