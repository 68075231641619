import Tooltip from '@mui/material/Tooltip';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import { CONFIGURATOR_URL } from 'adp-panel/constants/config';
import { CustomBadge } from 'adp-panel/pages/Dashboard/Inbox/TicketsGrid';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import CustomButton from 'components/Button/CustomButton';
import { DEVICE_EDIT } from 'constants/routes';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from '@tanstack/react-query';
import React, { ComponentPropsWithoutRef, useEffect, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useRedirectStore } from 'reducers/redirectReducer';
import useUserData from '../../../../hooks/useUserData';
import { ReactComponent as PlayIcon } from 'assets/play-square.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon.svg';
import {
  SupportTicketAndMessagesEntry,
  SupportTicketsQueryParams,
  SupportTicketStatusEnum
} from '../../../api/tickets/tickets.types';
import MessageModal from '../../../components/Modals/MessageModal';
import TicketModal from '../../../components/Modals/TicketModal';
import DotsBackground from 'assets/dots.svg';
import { useMessageMarkArchive, useMessageMarkRead } from '../../../hooks/api/useMessages';
import { useModal } from '../../../hooks/api/useModal';
import {
  SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY,
  useSupportTicketsAndMessages,
  useTicketClose,
  useTicketReOpen
} from '../../../hooks/api/useTickets';
import CustomTable, { ActionsWrapper, TableHeader } from 'adp-panel/components/Table/CustomTable';
import {
  SupportTicketEntry,
  SupportTicketMessageEntry,
  SupportTicketAttachmentEntry
} from '../../../api/tickets/tickets.types';
import Card from 'adp-panel/components/Card/Card';
import { Chip, ChipOwnProps, CircularProgress, Link, Typography } from '@mui/material';
import { ReactComponent as MessagesSvg } from 'assets/messages.svg';
import LoaderWrapper from 'components/Loader/Loader';
import styled from 'styled-components';
import { MessageEntry } from 'adp-panel/api/messages/messages.types';

dayjs.extend(utc);
dayjs.extend(timezone);

const checkIsRecordProblemTicket = (ticket: SupportTicketEntry) => {
  if (ticket?.messages?.length === 0) return false;
  return !!Array.from(ticket.messages).find((message: SupportTicketMessageEntry) => {
    if (message.attachments === undefined) {
      return;
    }
    if (message.attachments.length === 0) {
      return;
    }

    return Array.from(message.attachments).find(
      (attachment: SupportTicketAttachmentEntry) => attachment.type === 'application/json'
    );
  });
};

interface CustomChip extends ChipOwnProps {
  sx?: any;
  status: 'open' | 'closed' | 'new' | 'reopened';
}

const CustomChip = ({ sx, ...props }: CustomChip & ComponentPropsWithoutRef<'div'>) => {
  const style = {
    ...(props.status === 'open' && {
      border: '1px solid #ABEFC6',
      backgroundColor: '#ECFDF3',
      color: '#067647'
    }),
    ...(props.status === 'closed' && {
      border: '1px solid #FECDCA',
      backgroundColor: '#FEF3F2',
      color: '#B42318'
    }),
    ...(props.status === 'new' && {
      border: '1px solid #ABEFC6',
      backgroundColor: '#ECFDF3',
      color: '#079455'
    }),
    ...(props.status === 'reopened' && {
      border: '1px solid #FEDF89',
      backgroundColor: '#FFFAEB',
      color: '#B54708'
    }),
    fontWeight: 600,
    ...sx
  };

  return <Chip sx={{ ...style, ...sx }} {...props} />;
};

export const transformTicketFromAPI = (ticket: SupportTicketEntry) => {
  const formatDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');
  const formatDateDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY');
  const formatDateHour = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('HH:mm');

  const formatTitle = (title: string) =>
    title?.length > 250 ? title.substring(0, 250) + '...' : title;

  const formatMessage = (message: SupportTicketMessageEntry) => ({
    ...message,
    created_at: formatDate(message.created_at),
    created_at_date: formatDateDate(message.created_at),
    created_at_hour: formatDateHour(message.created_at)
  });

  const transformTicket = (ticket: SupportTicketEntry) => {
    const titleFromMessageContent = ticket?.messages[0]?.content
      ? formatTitle(ticket.messages[0].content)
      : '';
    const ticketTitle =
      ticket?.messages[0]?.title && ticket.messages[0].title.length > 0
        ? formatTitle(ticket.messages[0].title)
        : titleFromMessageContent;

    const lastMessageDate =
      ticket.messages.length > 0
        ? formatDate(ticket.messages[ticket.messages.length - 1].created_at)
        : null;

    return {
      id: ticket.id,
      from: ticket.sender.name,
      status: ticket.status,
      type: 'SupportTicket',
      date: formatDate(ticket.created_at),
      lastMessageDate: lastMessageDate,
      isRecordReplay: checkIsRecordProblemTicket(ticket),
      title: ticketTitle,
      messages: ticket?.messages?.map(formatMessage) ?? []
    };
  };

  return transformTicket(ticket);
};

const transformMessageFromAPI = (messageEntry: MessageEntry) => {
  const formatDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');
  const formatDateDate = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY');
  const formatDateHour = (date: string) => dayjs(date).tz(dayjs.tz.guess()).format('HH:mm');

  return {
    id: messageEntry.id,
    from: messageEntry.message.sender?.name,
    type: 'UserMessage',
    date: formatDate(messageEntry.message.created_at),
    lastMessageDate: formatDate(messageEntry.message.created_at),
    title: messageEntry.message.title || messageEntry.message.content,
    isRecordReplay: false,
    messages: [],
    message: {
      ...messageEntry.message,
      created_at_date: formatDateDate(messageEntry.message.created_at),
      created_at_hour: formatDateHour(messageEntry.message.created_at)
    },
    is_read: messageEntry.is_read
  };
};

const mapTicketData = (apiData: SupportTicketAndMessagesEntry[]) => {
  return (
    apiData?.map((entry) => {
      if (entry.type === 'SupportTicket') {
        return transformTicketFromAPI(entry.item as SupportTicketEntry);
      } else {
        return transformMessageFromAPI(entry.item as MessageEntry);
      }
    }) ?? []
  );
};

const NoMessages = () => (
  <>
    <TableHeader tableHeader='Inbox' />
    <div style={{ display: 'flex', justifyContent: 'center', padding: '0' }}>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '20px',
          padding: '40px 180px',
          backgroundImage: `linear-gradient(to bottom, rgba(255,255, 255, 0.5), rgba(255, 255, 255, 1)), url(${DotsBackground})`
        }}>
        <Card style={{ display: 'flex', alignItems: 'center' }}>
          <MessagesSvg />
        </Card>
        <Typography sx={{ fontWeight: 600 }}>
          <Translation ns='dashboard'>
            {(t) => {
              return t('dashboard:inbox.grid.no_messages', 'No messages');
            }}
          </Translation>
        </Typography>
      </div>
    </div>
  </>
);

const MessageWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const InboxGrid = () => {
  const { t } = useTranslation('dashboard');
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const [selectedTicket, setSelectedTicket] = useState<number | undefined>();
  const {
    isOpen: isMessageModalOpen,
    handleOpen: handleMessageModalOpen,
    handleClose: handleMessageModalClose
  } = useModal();
  const { isOpen: isModalOpen, handleOpen, handleClose } = useModal();
  const { mutateAsync: markMessageArchive } = useMessageMarkArchive();
  const { mutateAsync: markMessageRead } = useMessageMarkRead();
  const { mutateAsync: closeTicket } = useTicketClose();
  const { mutateAsync: reopenTicket } = useTicketReOpen();
  const queryClient = useQueryClient();
  const { setTicketRedirectId, ticketId } = useRedirectStore((state) => ({
    setTicketRedirectId: state.setTicketRedirectId,
    ticketId: state.ticketId
  }));
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });

  const handleShowTicket = (ticketId: number) => {
    setSelectedTicket(ticketId);
    handleOpen();
  };

  const handleCloseTicket = async (ticketId: number) => {
    await closeTicket(ticketId);
    await queryClient.invalidateQueries([SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY]);
  };

  const handleReopenTicket = async (ticketId: number) => {
    await reopenTicket({ ticketId });
    await queryClient.invalidateQueries([SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY]);
  };

  const handleReadMessage = async (messageId: number) => {
    await markMessageRead({ messageId });
    await queryClient.invalidateQueries([SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY]);
  };

  const handleArchiveMessage = async (messageId: number) => {
    await markMessageArchive({ messageId });
    await queryClient.invalidateQueries([SUPPORT_TICKETS_AND_MESSAGES_QUERY_KEY]);
  };

  // Handles showing ticket from link redirect
  useEffect(() => {
    if (ticketId) {
      handleShowTicket(ticketId);
      setTicketRedirectId({ ticketId: null });
    }
  }, [ticketId]);

  const handleRedirect = (id: any) => {
    window.open(`${CONFIGURATOR_URL}/support-ticket?ticketId=${id}`, '_blank');
  };

  const ticketStatusMap = new Map<string, string>([
    [SupportTicketStatusEnum.new, 'New'],
    [SupportTicketStatusEnum.inProgress, 'In progress'],
    [SupportTicketStatusEnum.closed, 'Closed'],
    [SupportTicketStatusEnum.reopened, 'Re-opened']
  ]);

  const commonQueryParams: SupportTicketsQueryParams = {
    perpage: 10,
    page: paginationModel.page + 1
  };

  const {
    result: ticketsAndMessagesData,
    total: totalTickets,
    isLoading: isLoadingTickets
  } = useSupportTicketsAndMessages(commonQueryParams, true);
  const dataForTableExists = !isLoadingTickets && ticketsAndMessagesData.length > 0;

  const showInbox = () => {
    if (isLoadingTickets)
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );

    return (
      <Card>
        {dataForTableExists ? (
          <CustomTable
            tableData={ticketsAndMessagesData}
            tableHeader={t('dashboard:inbox.grid.table_header', 'Inbox')}
            totalItems={totalTickets ?? 0}
            isLoading={isLoadingTickets}
            disableRowSelectionOnClick
            sx={{ maxHeight: '420px' }}
            columns={[
              {
                field: 'item.title',
                headerName: t('dashboard:inbox.grid.columns.topic', 'Topic'),
                flex: 1,
                renderCell: (params) => {
                  const { status, type } = params.row;

                  return (
                    <MessageWrapper>
                      {type !== 'UserMessage' && (
                        <CustomChip
                          size='small'
                          color='primary'
                          status={status}
                          variant='outlined'
                          sx={{ borderRadius: '6px', padding: '0', marginRight: '12px' }}
                          label={ticketStatusMap.get(status)}
                        />
                      )}
                      {type === 'UserMessage' ? (
                        <NavLink
                          onClick={() => {
                            setSelectedMessage(params.row);
                            handleMessageModalOpen();
                          }}
                          to='#'>
                          {params.row.title}
                        </NavLink>
                      ) : (
                        <NavLink to={`/tickets/${params.id}`}>{params.row.title}</NavLink>
                      )}
                    </MessageWrapper>
                  );
                }
              },
              {
                field: 'from',
                headerName: t('dashboard:inbox.grid.columns.sender', 'From'),
                flex: 1,
                sortable: false
              },
              {
                field: 'lastMessageDate',
                headerName: t('dashboard:inbox.grid.columns.date', 'Created at'),
                flex: 1
              },
              {
                field: 'actions',
                width: 100,
                headerName: '',
                renderCell: (params) => {
                  const { id, status, isRecordReplay } = params.row;

                  if (status === 'UserMessage') {
                    return (
                      <ActionsWrapper>
                        <Tooltip
                          title={t(
                            'dashboard:inbox.grid.tooltip.archive_message',
                            'Archive message'
                          )}>
                          <span>
                            <CustomButton
                              Icon={TrashIcon}
                              color='light'
                              data-testid='button-archive-message'
                              onClick={() => handleArchiveMessage(id)}
                            />
                          </span>
                        </Tooltip>
                      </ActionsWrapper>
                    );
                  }

                  return (
                    <ActionsWrapper>
                      {isRecordReplay && (
                        <Tooltip title={t('dashboard:inbox.grid.tooltip.play', 'Play record')}>
                          <CustomButton
                            Icon={PlayIcon}
                            onClick={() => handleRedirect(id)}
                            data-testid='button-edit'
                            color='light'
                          />
                        </Tooltip>
                      )}
                      {status !== SupportTicketStatusEnum.closed && (
                        <Tooltip title={t('dashboard:inbox.grid.tooltip.close', 'Close ticket')}>
                          <span>
                            <CustomButton
                              Icon={TrashIcon}
                              color='light'
                              data-testid='button-close-ticket'
                              onClick={() => handleCloseTicket(id)}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {status === SupportTicketStatusEnum.closed && (
                        <Tooltip title={t('dashboard:inbox.grid.tooltip.reopen', 'Reopen ticket')}>
                          <span>
                            <CustomButton
                              Icon={TrashIcon}
                              color='light'
                              data-testid='button-delete'
                              onClick={() => handleReopenTicket(id)}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </ActionsWrapper>
                  );
                }
              }
            ]}
            mapTableData={mapTicketData}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        ) : (
          <NoMessages />
        )}
      </Card>
    );
  };

  return (
    <div>
      {isModalOpen && selectedTicket && (
        <TicketModal ticketId={selectedTicket} handleClose={handleClose} />
      )}
      {isMessageModalOpen && selectedMessage && (
        <MessageModal
          title={selectedMessage.message.title || ''}
          content={selectedMessage.message.content}
          date={selectedMessage.message.created_at_date}
          hour={selectedMessage.message.created_at_hour}
          sender={selectedMessage.from}
          handleClose={handleMessageModalClose}
          onModalOpen={() =>
            !selectedMessage.is_read ? handleReadMessage(selectedMessage.id) : null
          }
        />
      )}
      {showInbox()}
    </div>
  );
};
