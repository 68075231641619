import React from 'react';
import { IconButton, InputAdornment, InputLabel, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { commonFormStyles, LabelStyle, LabelWrapper } from './commonStyles';

const CustomPasswordField = ({
  id,
  label,
  control,
  optional = false,
  disabled = false,
  helperText = ''
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const { t } = useTranslation();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Controller
      control={control}
      name={id}
      render={({ field, fieldState }) => {
        const messages = fieldState.error?.types;
        let validationMessage = '';

        if (messages && Object.entries(messages).length > 1) {
          validationMessage =
            t('common:password_strength.initial_message') +
            ' ' +
            Object.entries(messages)
              .map((message) => {
                const translationKey = message[1];
                return typeof translationKey === 'string'
                  ? t(translationKey)
                  : // @ts-ignore
                    t(translationKey.key, translationKey.values);
              })
              .join(', ');
        } else {
          validationMessage = t(fieldState.error?.message || '');
        }
        return (
          <div style={{ flex: '1' }}>
            <InputLabel
              shrink={false}
              htmlFor={id}
              sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
              <LabelWrapper>
                <Typography sx={{ ...LabelStyle }}>{label}</Typography>
                {optional && (
                  <Typography>({t('common:password_field.optional_label', 'Optional')})</Typography>
                )}
              </LabelWrapper>
            </InputLabel>
            <TextField
              {...field}
              id={id}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={validationMessage ? validationMessage : helperText}
              size='small'
              disabled={disabled}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                },
                inputLabel: { shrink: true }
              }}
              type={showPassword ? 'text' : 'password'}
            />
          </div>
        );
      }}
    />
  );
};

export default CustomPasswordField;
