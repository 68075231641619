import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Button, MenuItem, TextField } from '@mui/material';
import { toast } from 'react-hot-toast';
import { transformAndApply } from 'configurator/utils/Config/transformConfig';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import ConfigComponent from 'configurator/components/organisms/ConfigComponent/ConfigComponent';
import { useConfigStore } from 'configurator/reducers/configStore';
import { Header1, HeaderWrapper } from '../styled';
import Divider from '../../../components/atoms/Divider/Divider';
import useRemoteSession from '../../../hooks/useRemoteSession';
import { ButtonWrapper, HeaderInnerWrapper, TableBody, TableHeader } from './styled';
import { ConfigHistoryItem } from 'configurator/components/organisms/Config/ConfigHistoryItem';
import CustomPageHeader from 'configurator/components/atoms/Typography/CustomHeader';
import Card from 'adp-panel/components/Card/Card';
import { useTranslation } from 'react-i18next';

export const HeaderMode = styled.h3`
  font-size: 20px;
  margin: 10px 0;
  color: ${({ theme }) => theme.colorPrimary};
`;

const transformHistory = (differences: any) => {
  let common: any = [];
  const modes: any = [];

  if (!isEmpty(differences.common.before)) {
    common = Object.keys(differences.common.before).map((key) => ({
      after: differences.common.after[`${key}`],
      before: differences.common.before[`${key}`],
      key
    }));
  }
  differences.modes.forEach((mode: any) => {
    if (!isEmpty(mode?.before) && mode?.before) {
      modes.push({
        changes: Object.keys(mode.before).map((key) => ({
          after: mode.after[`${key}`],
          before: mode.before[`${key}`],
          key
        })),
        slot: mode.slot,
        name: mode.name
      });
    }
  });

  return { common, modes };
};

const SessionHistory = () => {
  const { config, configHistory, consumeHistory, importConfig } = useConfigStore((state: any) => ({
    config: state.config,
    configHistory: state.configHistory,
    consumeHistory: state.consumeHistory,
    importConfig: state.importConfig
  }));
  const { t } = useTranslation();
  const sessionRestorePoints = useLiveConfiguratorStore((state: any) => state.sessionRestorePoints);
  const openModal = useUiStore((state: any) => state.openModal);
  const { consumeAllSessionChanges } = useRemoteSession();
  const [configRestorePoints, setConfigRestorePoints] = useState<any[]>([]);
  const [selectedEntry, setSelectedEntry] = useState(0);
  const [selectedModeSlot, setSelectedModeSlot] = useState(0);

  const handleUndo = (historyChangeId: number) => {
    consumeHistory(historyChangeId);
  };

  useEffect(() => {
    if (sessionRestorePoints && sessionRestorePoints.length > 0) {
      setConfigRestorePoints(
        sessionRestorePoints.map((item: any, index: any) => ({
          name: `Restore point #${index + 1}`,
          ...item
        }))
      );
    } else {
      setConfigRestorePoints([]);
    }
  }, [sessionRestorePoints]);

  const restoreAllChanges = () => {
    openModal(MODALS.resetAllChanges);
  };

  const restorePoint = async (selectedItem: any) => {
    importConfig(configRestorePoints[selectedItem]);
    consumeAllSessionChanges(false);
    toast.success('Restore point imported');
  };

  const findRestorePoint = (id: any) =>
    configRestorePoints.find((restorePoint) => restorePoint.id === id);

  const completeConfigRestorePoint = {
    ...findRestorePoint(selectedEntry)?.common,
    ...findRestorePoint(selectedEntry)?.modes.find((mode: any) => mode.slot === selectedModeSlot)
      .config
  };

  return (
    <>
      <HeaderWrapper>
        <CustomPageHeader
          header='Session history'
          tooltipText={t('configurator:header.session_history.tooltip', 'Tooltip')}
        />
        <ButtonWrapper>
          <Button onClick={restoreAllChanges}>Reset all changes</Button>
        </ButtonWrapper>
      </HeaderWrapper>
      <Card>
        <TableBody>
          <TableHeader>Configuration</TableHeader>
          <TableHeader>Value before</TableHeader>
          <TableHeader>Value after</TableHeader>
          <TableHeader>Value now</TableHeader>
        </TableBody>
        {configHistory.length > 0 &&
          configHistory
            .slice()
            .reverse()
            .map((historyEntry: any, index: any) => (
              <div key={historyEntry.timestamp + Math.random()} style={{ marginTop: '24px' }}>
                <HeaderInnerWrapper>
                  <p style={{ fontWeight: 'bold' }}>Change {configHistory.length - index}</p>
                  {index === 0 && (
                    <Button
                      size='small'
                      onClick={() => handleUndo(historyEntry.id)}
                      color='secondary'>
                      Restore
                    </Button>
                  )}
                </HeaderInnerWrapper>
                {transformHistory(historyEntry.diffConfig).common.map((change: any) => (
                  <ConfigHistoryItem
                    key={historyEntry.timestamp + Math.random()}
                    name={change.key}
                    configName={change.key}
                    before={change.before}
                    after={change.after}
                    now={config.common.config}
                  />
                ))}
                {transformHistory(historyEntry.diffConfig).modes.map((mode: any) => (
                  <>
                    <HeaderMode>Mode: {mode.name}</HeaderMode>
                    {mode.changes.map((change: any) => (
                      <ConfigHistoryItem
                        key={historyEntry.timestamp + Math.random()}
                        name={change.key}
                        configName={change.key}
                        before={change.before}
                        after={change.after}
                        now={{
                          ...config.common.config,
                          ...config.modes.find((_mode: any) => _mode.slot === mode.slot)?.config
                        }}
                      />
                    ))}
                  </>
                ))}
              </div>
            ))}
      </Card>
      <Divider margin='40px' />
      {configRestorePoints && configRestorePoints.length > 0 && (
        <>
          <HeaderWrapper>
            <HeaderInnerWrapper>
              <CustomPageHeader
                header='Restore point'
                tooltipText={t('configurator:header.restore_point.tooltip', 'Tooltip')}
              />
              {config.modes && (
                <TextField
                  fullWidth
                  id='selected-mode'
                  label='Mode shown'
                  select
                  sx={{ width: '150px' }}
                  SelectProps={{
                    value: selectedModeSlot,
                    onChange: (e: any) => setSelectedModeSlot(e.target.value)
                  }}>
                  {config.modes.map((mode: any) => (
                    <MenuItem key={`selected-mode_${mode.name}`} value={mode.slot}>
                      {mode.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <Button onClick={() => restorePoint(selectedEntry)} color='secondary'>
                Load
              </Button>
            </HeaderInnerWrapper>
            <HeaderInnerWrapper>
              <TextField
                id='selected-entry'
                select
                label='Entry name'
                SelectProps={{
                  onChange: (e) =>
                    setSelectedEntry(
                      configRestorePoints.find((item) => item?.id === e.target.value).id
                    ),
                  value: findRestorePoint(selectedEntry).id
                }}>
                {configRestorePoints.map((item) => (
                  <MenuItem key={`selected-entry_${item.name}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </HeaderInnerWrapper>
          </HeaderWrapper>
          <ConfigComponent
            config={completeConfigRestorePoint || null}
            transformedConfig={transformAndApply(completeConfigRestorePoint)}
          />
        </>
      )}
    </>
  );
};

export default SessionHistory;
