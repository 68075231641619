import styled from 'styled-components';
import Navbar from 'adp-panel/components/Navbar/Navbar';
import PropTypes from 'prop-types';
import Sidebar from 'adp-panel/components/Navbar/Sidebar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const InnerWrapper = styled.div`
  display: flex;
`;

const MainWrapper = styled.main`
  background-color: ${({ theme }) => theme.palette.background};
  padding: 24px;
  width: 100%;
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled.footer`
  display: flex;
  padding: 5px 10px;
  font-size: 10px;
  background-color: ${({ theme }) => theme.palette.background};
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  flex-grow: 1;
`;

const DefaultLayout = ({ children }: any) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <Sidebar />
        <MainContentWrapper>
          <Navbar />
          <MainWrapper>{children}</MainWrapper>
        </MainContentWrapper>
      </InnerWrapper>
      {/* <Footer>
        <div>ADP version: {process.env.REACT_APP_SOFTWARE_VERSION}</div>
      </Footer> */}
    </Wrapper>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DefaultLayout;
