import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	*, *::before, *::after {
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html {
		font-size: 62.5%;
	}

	body {
		padding: 0 !important;
		margin: 0;
	}

	ul, li, nav {
		padding: 0;
		margin: 0;
	}

	p {
		margin: 0;
		padding: 0;
	}

	h1, h2, h3, h4, h5 {
		padding: 0;
		margin: 0;
		font-size: inherit;
		font-weight: inherit;
	}

	html {
		font-size: 16px;
		height: 100%;
	}

	body {
		font-family: 'Inter', sans-serif;
		height: 100%;
		background-color: #f9fafb;
	}

	#chat-widget-container {
		z-index: 9999 !important;
	}

	#root {
		height: 100%;
	}

	::-ms-reveal {
		display: none
	}
`;

export default GlobalStyle;
