import CustomButton from 'components/Button/CustomButton';
import CustomChip from 'components/CustomChip/CustomChip';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import { CHOOSE_GRIPS } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HandDeviceSvg } from 'assets/hand-device.svg';
import { ReactComponent as DisconnectBluetoothSvg } from 'assets/bt.svg';
import useNavbarConnect from 'configurator/hooks/bluetooth/useNavbarConnect';

const ConnectButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { connected, serial } = useDeviceInfoStore((state) => ({
    connected: state.connected,
    serial: state.serial
  }));
  const { handleBluetooth } = useNavbarConnect();

  return (
    <div style={{ display: 'flex', gap: '12px' }}>
      {connected && (
        <CustomButton color='light' onClick={() => navigate(CHOOSE_GRIPS)}>
          <CustomChip
            color='success'
            label={t('common:component.navbar.connected_sn', {
              defaultValue: 'Connected (SN: {{serial}})',
              serial
            })}
            sx={{ borderRadius: '6px', padding: '0', marginRight: '12px', height: '0' }}
          />
          {t('common:component.navbar.back_to_configurator', 'Back to configurator')}
        </CustomButton>
      )}
      <CustomButton
        color={connected ? 'light' : 'primary'}
        Icon={connected ? DisconnectBluetoothSvg : HandDeviceSvg}
        onClick={() => handleBluetooth()}>
        {connected
          ? t('common:component.navbar.disconnect_sn', {
              defaultValue: 'Disconnect (SN: {{serial}})',
              serial
            })
          : t('common:component.navbar.connect_device', 'Connect to Zeus')}
      </CustomButton>
    </div>
  );
};

export default ConnectButton;
