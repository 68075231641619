import { styled } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { UserDetails } from 'adp-panel/api/authentication/authentication.types';

import {
  SupportTicketAttachmentEntry,
  SupportTicketEntry,
  SupportTicketExtendOptions,
  SupportTicketMessageEntry,
  SupportTicketQueryParams,
  SupportTicketStatusEnum,
  SupportTicketTypeEnum,
  TicketMessagePayload
} from 'adp-panel/api/tickets/tickets.types';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import { TICKET_DEBUG } from 'adp-panel/constants/config';
import { AudioMime, ImageMime, PDFMime, VideoMime } from 'adp-panel/constants/mimeTypes';
import {
  useTicket,
  useTicketClose,
  useTicketMessageCreate,
  useTicketReadMessage,
  useTicketReOpen
} from 'adp-panel/hooks/api/useTickets';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import AttachmentFileIcon from 'adp-panel/pages/Dashboard/Inbox/AttachmentFileIcon';
import AttachmentHandler, {
  UploadFileInfo
} from 'adp-panel/pages/Dashboard/Inbox/AttachmentHandler';
import FilePreview from 'adp-panel/pages/Dashboard/Inbox/FilePreview';
import { transformTicketFromAPI } from 'adp-panel/pages/Dashboard/Inbox/Inbox';
import { PermissionError } from 'adp-panel/pages/Dashboard/Inbox/styled';
import {
  EditableInput,
  ResponseChatWrapper,
  StyledAudioPlayer
} from 'adp-panel/pages/Dashboard/styled';
import { getValidFiles } from 'adp-panel/utils/getFileName';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import CustomButton from 'components/Button/CustomButton';
import CustomIcon, { CustomTicketIcon } from 'components/CustomIcon/CustomIcon';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useTypedParams } from 'hooks/useTypedParams';
import useUserData, { UserDataType } from 'hooks/useUserData';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Stack,
  Typography
} from '@mui/material';
import LoaderWrapper from 'components/Loader/Loader';
import CustomAvatar, { stringToColor } from 'adp-panel/components/CustomAvatar/CustomAvatar';

dayjs.extend(utc);
dayjs.extend(timezone);

const StyledTopicLabel = styled(Typography)`
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: '#475467';
`;

const StyledTopicText = styled(Typography)`
  color: #101828;
  font-size: 16px;
  font-weight: 500;
`;

const formatDate = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');
const formatTime = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('HH:mm');

const Message = ({
  content,
  attachments,
  created_at,
  sender,
  me
}: SupportTicketMessageEntry & { me: UserDetails }) => {
  const { t } = useTranslation('dashboard');
  const myMessage = me?.id === sender?.id;

  const attachmentPreview = (myAttachments: SupportTicketAttachmentEntry[]) => {
    return (
      <div>
        {myAttachments &&
          myAttachments.map((item: SupportTicketAttachmentEntry) => {
            if (AudioMime.includes(item.type)) {
              return (
                <StyledAudioPlayer key={item.id} controls>
                  <source src={item.attachment} />
                </StyledAudioPlayer>
              );
            }
            if (PDFMime.includes(item.type)) {
              return (
                <Link sx={{ color: 'white' }} target='_blank' href={item.attachment}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '2px',
                      alignItems: 'center',
                      margin: '4px 0px'
                    }}>
                    <AttachmentFileIcon label='PDF' size={30} />
                    <span style={{ fontSize: '14px', marginLeft: '5px', color: 'white' }}>
                      {item.title}
                    </span>
                  </div>
                </Link>
              );
            }
            if (VideoMime.includes(item.type)) {
              return (
                <video key={item.id} controls width='450'>
                  <source src={item.attachment} type={item.type} />
                </video>
              );
            }
            if (ImageMime.includes(item.type)) {
              return (
                <img width={200} key={item.id} src={item.attachment} alt={'Message attachment'} />
              );
            }
          })}
      </div>
    );
  };

  if (myMessage) {
    return (
      <Stack direction='row' spacing={1}>
        <Box>
          <Typography variant='body2' sx={{ color: '#101828', fontWeight: 600 }}>
            {t('dashboard:component.ticket_details.message.your_message', 'You')}{' '}
            <span style={{ color: '#667085', fontWeight: 400 }}>{formatTime(created_at)}</span>
          </Typography>
          <Box
            sx={{
              p: '12px',
              mt: 0.5,
              color: 'white',
              backgroundColor: '#33499C',
              borderRadius: '0px 8px 8px 8px;'
            }}>
            <Typography sx={{ fontSize: '14px' }}>{content}</Typography>
            {attachmentPreview(attachments || [])}
          </Box>
        </Box>
      </Stack>
    );
  }

  return (
    <Stack direction='row' spacing={1} gap='5px' alignItems='flex-start'>
      <CustomAvatar
        name={sender?.name || ''}
        style={{
          width: '24px',
          height: '24px',
          fontSize: '12px',
          fontWeight: 600,
          bgcolor: stringToColor(sender?.name || '')
        }}
      />
      <Box>
        <Typography variant='body2' sx={{ color: '#101828', fontWeight: 600 }}>
          {sender?.name}{' '}
          <span style={{ color: '#667085', fontWeight: 400 }}>{formatTime(created_at)}</span>
        </Typography>
        <Box
          sx={{ p: '12px', mt: 0.5, backgroundColor: '#F2F4F7', borderRadius: '0px 8px 8px 8px;' }}>
          {content && (
            <Typography sx={{ fontSize: '14px', color: '#101828' }}>{content}</Typography>
          )}
          {attachmentPreview(attachments || [])}
        </Box>
      </Box>
    </Stack>
  );
};
export const TicketDetails = () => {
  const { t } = useTranslation('dashboard');
  const editableRef = useRef<HTMLDivElement>(null);
  const [attachments, setAttachments] = useState<UploadFileInfo[]>([]);

  const [initialValues, setInitialValue] = useState<{
    response: string | null;
    attachments: string[] | null | any[];
  }>({
    response: null,
    attachments: null
  });
  const { ticketId } = useTypedParams(['ticketId']);
  const queryParams: SupportTicketQueryParams = {
    extend: [
      SupportTicketExtendOptions.sender,
      SupportTicketExtendOptions.recipient,
      SupportTicketExtendOptions.messages,
      SupportTicketExtendOptions.messagesAttachments,
      SupportTicketExtendOptions.messageSender
    ]
  };
  const {
    result: ticketDataRaw,
    refetch,
    isLoading
  } = useTicket({ ticketId: Number(ticketId), params: queryParams });
  const ticketData = ticketDataRaw as SupportTicketEntry;
  const { mutateAsync: createMessage, isLoading: createLoading } = useTicketMessageCreate();

  const { mutateAsync: readTicketMessage } = useTicketReadMessage();
  const { mutateAsync: closeTicket, isLoading: isCloseTicketLoading } = useTicketClose();
  const { mutateAsync: reopenTicket, isLoading: isReopenTicketLoading } = useTicketReOpen();
  const { rolesByName, me } = useUserData();

  useEffect(() => {
    if (ticketData) {
      const unreadMessages = ticketData.messages.filter(
        (message: any) => !message.is_read && message.sender_id !== me?.id
      );
      Promise.all(
        unreadMessages.map((message: any) =>
          readTicketMessage({ ticketId: Number(ticketId), messageId: message.id })
        )
      );
    }
  }, [JSON.stringify(ticketData)]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const onFilesChange = (files: UploadFileInfo[]) => {
    setAttachments(files);
  };

  const sendMessage = async () => {
    const content = editableRef.current?.innerText.trim() || '';
    // @ts-ignore
    const parsedAttachments = getValidFiles(attachments, true);

    if (!content && parsedAttachments.length === 0) return;

    // @ts-ignore
    const messageData: TicketMessagePayload = {
      ticketId: Number(ticketId),
      ...(content && { content }),
      ...(parsedAttachments && parsedAttachments.length > 0 && { attachments: parsedAttachments })
    };
    setInitialValue({
      response: messageData.content,
      attachments: messageData.attachments || null
    });
    try {
      await createMessage(messageData);
      await refetch();
      setInitialValue({
        response: null,
        attachments: null
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    } finally {
      const element = editableRef.current;
      if (element) {
        element.innerHTML = '';
      }
      setAttachments([]);
    }
  };

  const handleCloseTicket = async () => {
    try {
      await closeTicket(Number(ticketId));
      refetch();
    } catch (e) {
      Sentry.captureException(e);
      console.log('error', e);
    }
  };

  const handleReopenTicket = async () => {
    await reopenTicket({ ticketId: Number(ticketId) });
    refetch();
  };

  const canSeeMessages = () => {
    if (TICKET_DEBUG && userHasPermissions([RoleEnum.superAdmin], rolesByName)) {
      return true;
    }
    return userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName);
  };

  const callbackEvent = debounce(() => {
    const event = new CustomEvent('clickOnMedia', { detail: {}, bubbles: true });
    document.dispatchEvent(event);
  }, 100);

  useEffect(() => {
    const mediaElements = document.querySelectorAll('audio, video');

    const events = [
      'abort',
      'canplay',
      'canplaythrough',
      'durationchange',
      'ended',
      'error',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'pause',
      'play',
      'playing',
      'progress',
      'ratechange',
      'seeked',
      'seeking',
      'stalled',
      'suspend',
      'timeupdate',
      'volumechange',
      'waiting',
      'mouseover'
    ];

    mediaElements.forEach((mediaElement) => {
      events.forEach((event) => {
        mediaElement.addEventListener(event, callbackEvent);
      });
    });

    return () => {
      mediaElements.forEach((mediaElement) => {
        events.forEach((event) => {
          mediaElement.removeEventListener(event, callbackEvent);
        });
      });
    };
  }, [isLoading]);

  const canReplayToMessage = () => {
    return canSeeMessages() && ticketData && ticketData.status !== SupportTicketStatusEnum.closed;
  };

  const canCloseTicket = () => {
    return ticketData && ticketData.status !== SupportTicketStatusEnum.closed;
  };
  const canReopenTicket = () => {
    return (
      ticketData &&
      ticketData.messages?.length > 0 &&
      ticketData.messages[0].title === 'New config update' &&
      ticketData.status === SupportTicketStatusEnum.closed
    );
  };

  const handleRemove = (uid: string) => {
    const updatedAttachments = attachments.filter((file) => file.uid !== uid);
    setAttachments(updatedAttachments);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();

    const clipboardData = e.clipboardData || window.Clipboard;
    const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = pastedData;

    let textContent = '';
    tempDiv.childNodes.forEach((node) => {
      if (node.nodeName === 'P') {
        textContent += (node.textContent || '') + '\n\n'; // Replace <p> with double newline
      } else {
        textContent += node.textContent;
      }
    });

    // Insert the sanitized text at the cursor position
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(document.createTextNode(textContent));
    selection.collapseToEnd();
  };

  const handleInput = () => {
    const element = editableRef.current;
    if (element && !element.textContent?.trim()) {
      element.innerHTML = '';
    }
  };

  if (isLoading)
    return (
      <DefaultLayout>
        <LoaderWrapper style={{ height: '85vh' }}>
          <CircularProgress />
        </LoaderWrapper>
      </DefaultLayout>
    );

  const ticket = transformTicketFromAPI(ticketData);

  return (
    <DefaultLayout>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '4fr 1fr',
          gap: '24px',
          alignItems: 'start'
        }}>
        <Card>
          <Box
            sx={{
              p: 2,
              height: '85vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
            <Stack direction='column-reverse' spacing={2} sx={{ overflowY: 'auto' }}>
              {canSeeMessages() &&
                ticketData?.messages &&
                [...ticketData.messages]
                  .reverse()
                  .map((message: SupportTicketMessageEntry) => (
                    <Message key={message.id} {...{ ...message, me: me }} />
                  ))}
              {!canSeeMessages() && (
                <PermissionError>
                  {t(
                    'dashboard:component.ticket_details.permission_error',
                    'You do not have permission to view chat history'
                  )}
                </PermissionError>
              )}
            </Stack>
            {canReplayToMessage() && (
              <Box sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  {attachments.map((file) => (
                    <div key={file.uid}>
                      <FilePreview
                        extension={file?.extension || ''}
                        fileName={file.name}
                        uid={file.uid}
                        onRemove={handleRemove}
                      />
                      {file.validationErrors && (
                        <ul>
                          {file.validationErrors.map((error, i) => (
                            <li key={i} style={{ color: 'red' }}>
                              {error}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ))}
                </Box>
                <ResponseChatWrapper>
                  <EditableInput
                    ref={editableRef}
                    contentEditable={true}
                    placeholder={t(
                      'dashboard:component.ticket_details.input_placeholder',
                      'Reply...'
                    )}
                    role='textbox'
                    onPaste={handlePaste}
                    onInput={handleInput}
                    onKeyDown={handleKeyDown}
                    aria-multiline='true'></EditableInput>
                  <Box display='flex' alignItems='center' justifyContent='end'>
                    <AttachmentHandler
                      attachments={attachments}
                      filePreview={false}
                      onFilesChange={onFilesChange}
                    />
                    <CustomButton
                      variant='contained'
                      color='primary'
                      sx={{ ml: 1 }}
                      loading={createLoading}
                      disabled={
                        createLoading || ticketData.status === SupportTicketStatusEnum.closed
                      }
                      onClick={sendMessage}
                      startIcon={<CustomTicketIcon name='send' />}>
                      {t('dashboard:component.ticket_details.send_message', 'Send')}
                    </CustomButton>
                  </Box>
                </ResponseChatWrapper>
              </Box>
            )}
          </Box>
        </Card>
        <Card>
          <CardContent>
            <Typography variant='h6' sx={{ fontSize: 16, fontWeight: 600 }} gutterBottom>
              {t('dashboard:component.ticket_details.title', 'Ticket details')}
            </Typography>
            <Stack spacing={2}>
              <Box>
                <StyledTopicLabel>
                  {t('dashboard:component.ticket_details.topic', 'Topic:')}
                </StyledTopicLabel>
                <StyledTopicText>{ticket.title}</StyledTopicText>
              </Box>
              <Box>
                <StyledTopicLabel>
                  {t('dashboard:component.ticket_details.from', 'From:')}
                </StyledTopicLabel>
                <Box display='flex' alignItems='center' gap={1}>
                  <CustomAvatar
                    name={ticketData?.sender?.name}
                    style={{
                      width: 24,
                      height: 24,
                      fontSize: 12,
                      bgcolor: stringToColor(ticketData?.sender?.name)
                    }}
                  />
                  <StyledTopicText>{ticketData?.sender?.name}</StyledTopicText>
                </Box>
              </Box>
              <Box>
                <StyledTopicLabel>
                  {t('dashboard:component.ticket_details.created_at', 'Created at:')}
                </StyledTopicLabel>
                <StyledTopicText>{formatDate(ticketData.created_at)}</StyledTopicText>
              </Box>
            </Stack>
          </CardContent>
          <Box sx={{ px: 2, pb: 2 }}>
            {canCloseTicket() && (
              <CustomButton
                color='light'
                fullWidth
                loading={isCloseTicketLoading}
                onClick={handleCloseTicket}
                disabled={isCloseTicketLoading}
                sx={{ color: '#B42318' }}
                startIcon={<CustomIcon name='lock' />}>
                <span>
                  {t('dashboard:component.ticket_details.button.close_ticket', 'Close ticket')}
                </span>
              </CustomButton>
            )}
            {canReopenTicket() && (
              <CustomButton
                color='light'
                fullWidth
                loading={isReopenTicketLoading}
                onClick={handleReopenTicket}
                disabled={isReopenTicketLoading}
                sx={{ color: '#B42318' }}
                startIcon={<CustomIcon name='lock' />}>
                <span>
                  {t('dashboard:component.ticket_details.button.reopen_ticket', 'Reopen ticket')}
                </span>
              </CustomButton>
            )}
          </Box>
        </Card>
      </div>
    </DefaultLayout>
  );
};

export default TicketDetails;
