import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@mui/material';
import Card from 'adp-panel/components/Card/Card';
import { CustomTabPanel } from 'components/TabPanel/CustomTabPanel';
import DeviceUsageTab from 'adp-panel/pages/DeviceUsage/DeviceUsageTab';
import { TabsEnum } from '../types/patient.types';
import { TabsContainer } from '../styled/PatientTabs.styled';
import useCanAccess from 'adp-panel/hoc/useCanAccess';
import PatientGoals from '../PatientGoals';

interface PatientTabsProps {
  devices: DeviceEntry[];
  selectedTab: TabsEnum;
  onTabChange: (event: React.SyntheticEvent, newValue: TabsEnum) => void;
}

export const PatientTabs: React.FC<PatientTabsProps> = ({ devices, selectedTab, onTabChange }) => {
  const { t } = useTranslation('patients');

  const { isLoading: isLoadingCanAccessGoals, canAccess: canAccessGoals } = useCanAccess({
    resource: 'goals'
  });

  return (
    <Card>
      <TabsContainer>
        <Tabs value={selectedTab} onChange={onTabChange}>
          <Tab
            label={t('patients:details.tabs.device_usage', 'Device usage')}
            id='patient-tab-0'
            aria-controls='patient-tabpanel-0'
          />
          <Tab label={t('patients:details.tabs.goals', 'Goals')} disabled={!canAccessGoals} />
        </Tabs>

        <CustomTabPanel value={selectedTab} index={TabsEnum.DEVICE_USAGE}>
          {devices !== undefined && <DeviceUsageTab devices={devices} />}
        </CustomTabPanel>

        <CustomTabPanel value={selectedTab} index={TabsEnum.GOALS}>
          {<PatientGoals />}
        </CustomTabPanel>
      </TabsContainer>
    </Card>
  );
};
