/* eslint-disable react/jsx-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  HandMovementStates,
  gripsGroupsOptionsMap,
  handMovementStatesMap
} from 'configurator/utils/definesLocal';
import useWatch from 'configurator/hooks/useWatch';
import TelemetryController from 'configurator/bluetooth-handler/telemetryController';
import { Chip, Typography } from '@mui/material';
import { gripsImagesMap } from 'configurator/utils/gripsImages';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const GripImage = styled.img`
  height: 30px;
  background-color: #d0d5dd;
  border-radius: 50%;
  padding: 2px;
`;

const handMovementIndicatorTuples: [HandMovementStates, any][] = [
  [
    HandMovementStates.idle,
    { backgroundColor: '#F9FAFB', icon: '#98A2B3', borderColor: '#EAECF0' }
  ],
  [
    HandMovementStates.opening,
    { backgroundColor: '#ECFDF3', icon: '#067647', borderColor: '#ABEFC6' }
  ],
  [
    HandMovementStates.closing,
    { backgroundColor: '#FEF3F2', icon: '#B42318', borderColor: '#FECDCA' }
  ]
];

const handMovementIndicatorsMap: Map<
  HandMovementStates,
  { backgroundColor: string; icon: string; borderColor: string }
> = new Map(handMovementIndicatorTuples);

const HandStatus = () => {
  const { t } = useTranslation();
  const telemetryData = useWatch(TelemetryController, ['prosthesisGrip', 'handMovementState']);
  const canShowStatus =
    telemetryData.prosthesisGrip !== undefined && telemetryData.handMovementState !== undefined;

  const backgroundColorAction = handMovementIndicatorsMap.get(telemetryData.handMovementState)
    ?.backgroundColor;
  const borderColorAction = handMovementIndicatorsMap.get(telemetryData.handMovementState)
    ?.borderColor;
  const iconColorAction = handMovementIndicatorsMap.get(telemetryData.handMovementState)?.icon;

  return canShowStatus ? (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <div>Grip</div>
        <Chip
          label={
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <GripImage
                src={gripsImagesMap.get(telemetryData.prosthesisGrip) as any}
                alt='Current grip'
              />
              <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                {t(gripsGroupsOptionsMap.get(telemetryData.prosthesisGrip))}
              </Typography>
            </div>
          }
          sx={{
            borderRadius: '6px',
            border: '1px solid #EAECF0',
            backgroundColor: '#F9FAFB',
            height: '40px'
          }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '8px' }}>
        <div>Action</div>
        <Chip
          label={
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InfoOutlinedIcon
                  sx={{
                    fill: iconColorAction
                  }}
                />
              </div>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  color: iconColorAction
                }}>
                {t(handMovementStatesMap.get(telemetryData.handMovementState) || '')}
              </Typography>
            </div>
          }
          sx={{
            borderRadius: '6px',
            backgroundColor: backgroundColorAction,
            fontSize: '14px',
            height: '40px',
            border: `1px solid ${borderColorAction}`
          }}
        />
      </div>
    </Wrapper>
  ) : (
    <></>
  );
};

export default HandStatus;
