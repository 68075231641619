import { gripsGroupsOptionsMap } from 'adp-panel/utils/definesLocal';

export type TimeGridUsageData = {
  id: string;
  label: string;
  // color: string;
  'Grip count': number;
  Percentage: string;
  description: string;
};

type GripTimeData = {
  group_by: number;
  instances: number;
  percentage: string;
  Percentage: string;
};

export const mapGripTimeData = (items: any): TimeGridUsageData[] => {
  const baseData = Array.from(gripsGroupsOptionsMap.entries()).map(([gripId, gripName]) => ({
    id: gripName,
    label: gripName,
    'Grip count': 0,
    Percentage: '0.00',
    description: `Time spent in ${gripName} grip`
  }));

  if (!items?.length) {
    return baseData;
  }

  const existingDataMap = new Map<number, GripTimeData>(
    items.map((item: GripTimeData) => [Number(item.group_by), item])
  );

  return baseData.map((baseItem) => {
    const existingItem = existingDataMap.get(
      Number(
        Array.from(gripsGroupsOptionsMap.entries()).find(([_, name]) => name === baseItem.id)?.[0]
      )
    );
    if (!existingItem) return baseItem;

    return {
      ...baseItem,
      'Grip count': existingItem.instances || 0,
      Percentage: existingItem.percentage ? parseFloat(existingItem.percentage).toFixed(2) : '0.00'
    };
  });
};
