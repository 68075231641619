import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  CircularProgress,
  InputAdornment,
  InputLabel,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker as DatePickerMui } from '@mui/x-date-pickers/DatePicker';

import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import {
  ActiveView,
  CalendarProps,
  DatePicker,
  DatePickerChangeEvent,
  DateRangePicker,
  DateRangePickerChangeEvent,
  MultiViewCalendar,
  MultiViewCalendarProps
} from '@progress/kendo-react-dateinputs';
import CustomButton from 'components/Button/CustomButton';
import { icons } from 'components/CustomIcon/CustomIcon';
import { commonFormStyles, LabelStyle, LabelWrapper } from 'components/FormFields/commonStyles';
import { languages } from 'components/FormFields/CustomLanguageSelect';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DatePeriod,
  PeriodEnum
} from 'adp-panel/api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { GripsWithAllType } from 'adp-panel/constants/grips';
import {
  DateRangeType,
  InstanceOption,
  PeriodType
} from 'adp-panel/components/DeviceUsageMonitoring/UsageMonitoringGraph';
import { TransformedExercise } from 'adp-panel/pages/Goals/utils';
import { useTranslation } from 'react-i18next';
import { StyledButton, StyledButtonGroup } from './styled';
import { GraphDescription } from './GraphDescription';

export const DeviceDropdown = ({
  setDevice,
  devices,
  selectedDevice
}: {
  setDevice: (device: DeviceEntry) => void;
  devices: DeviceEntry[];
  selectedDevice: DeviceEntry | undefined;
}) => {
  const handleDeviceChange = (event: DropDownListChangeEvent) => {
    setDevice(event.value);
  };

  return (
    <DropDownList
      data={devices}
      textField='serial'
      style={{ minWidth: 160 }}
      dataItemKey='id'
      label='Serial Number'
      value={selectedDevice}
      onChange={handleDeviceChange}
    />
  );
};

export const GripsDropdownFilter = ({
  setGrip,
  grips,
  selectedGrip
}: {
  setGrip: (grip: GripsWithAllType) => void;
  grips: GripsWithAllType[];
  selectedGrip: GripsWithAllType;
}) => {
  const { t } = useTranslation();

  const handleDropdownChange = (data: any) => {
    if (!data) return;
    setGrip(data);
  };

  const value = useMemo(
    () => selectedGrip && grips.find((v) => v.text === selectedGrip.text),
    [grips, selectedGrip]
  );

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <InputLabel
          shrink={false}
          htmlFor={'grips'}
          sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <LabelWrapper>
            <Typography sx={{ ...LabelStyle, fontSize: '14px' }}>
              {t('common:device_usage.filters.grips_label', 'Grips')}
            </Typography>
          </LabelWrapper>
        </InputLabel>
      </div>
      <Autocomplete
        id='grips'
        sx={{ width: '270px' }}
        options={grips}
        autoHighlight
        value={selectedGrip}
        size='small'
        clearIcon={false}
        onChange={(_, data) => handleDropdownChange(data)}
        renderInput={(params) => <TextField {...params} />}
        getOptionLabel={(option) => option.text ?? ''}
      />
    </div>
  );

  return (
    <DropDownList
      data={grips}
      textField='text'
      label='Grips'
      style={{ minWidth: 160 }}
      dataItemKey='id'
      value={selectedGrip}
      onChange={handleDropdownChange}
    />
  );
};

export const InstancesDropdownFilter = ({
  setInstance,
  instances,
  selectedInstance
}: {
  setInstance: (instance: InstanceOption) => void;
  instances: InstanceOption[];
  selectedInstance: InstanceOption;
}) => {
  const { t } = useTranslation();
  const handleDropdownChange = (data: any) => {
    setInstance(data);
  };

  const value = useMemo(
    () => selectedInstance && instances.find((v) => v.text === selectedInstance.text),
    [instances, selectedInstance]
  );

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <InputLabel
          shrink={false}
          htmlFor={'serial_number'}
          sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <LabelWrapper>
            <Typography sx={{ ...LabelStyle, fontSize: '14px' }}>
              {t('common:device_usage.filters.instances_label', 'Distribution type')}
            </Typography>
          </LabelWrapper>
        </InputLabel>
      </div>
      <Autocomplete
        id='instances'
        sx={{ width: '370px' }}
        options={instances}
        autoHighlight
        value={value}
        size='small'
        clearIcon={false}
        onChange={(_, data) => handleDropdownChange(data)}
        renderInput={(params) => <TextField {...params} />}
        getOptionLabel={(option) => option.text ?? ''}
      />
    </div>
  );

  return (
    <DropDownList
      data={instances}
      textField='text'
      dataItemKey='id'
      style={{ minWidth: 160 }}
      label='Distribution type'
      value={selectedInstance}
      onChange={handleDropdownChange}
    />
  );
};

export const PeriodDropdownFilter = ({
  setPeriod,
  periods,
  selectedPeriod
}: {
  setPeriod: (instance: PeriodType) => void;
  periods: PeriodType[];
  selectedPeriod: PeriodType;
}) => {
  const handleDropdownChange = (event: DropDownListChangeEvent) => {
    setPeriod(event.target.value);
  };

  return (
    <DropDownList
      data={periods}
      textField='text'
      dataItemKey='id'
      style={{ minWidth: 160 }}
      label='Distribution type'
      value={selectedPeriod}
      onChange={handleDropdownChange}
    />
  );
};

export const DeviceDropdownFilter = ({
  setDevice,
  devices,
  selectedDevice
}: {
  setDevice: (device: DeviceEntry) => void;
  devices: DeviceEntry[];
  selectedDevice: DeviceEntry;
}) => {
  const { t } = useTranslation();
  const handleDropdownChange = (data: DeviceEntry | null) => {
    if (data) {
      setDevice(data);
    }
  };

  const value = useMemo(
    () => devices.find((v) => v.id === selectedDevice?.id) || null,
    [devices, selectedDevice]
  );

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <InputLabel
          shrink={false}
          htmlFor={'serial_number'}
          sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <LabelWrapper>
            <Typography
              sx={{
                ...LabelStyle,
                fontSize: '14px'
              }}>
              {t('common:device_usage.filters.device_label', 'Serial number')}
            </Typography>
          </LabelWrapper>
        </InputLabel>
      </div>
      <Autocomplete
        id='serial_number'
        sx={{ width: '370px' }}
        options={devices}
        autoHighlight
        value={value}
        size='small'
        clearIcon={false}
        onChange={(_, data) => handleDropdownChange(data)}
        renderInput={(params) => <TextField {...params} />}
        getOptionLabel={(option) => option.serial ?? ''}
      />
    </div>
  );
};

export const DateButtonGroup = ({
  dateRange,
  period,
  setDate,
  setPeriod,
  additionalProps
}: {
  dateRange: DateRangeType;
  setDate: (dateRange: DateRangeType) => void;
  period: PeriodEnum;
  setPeriod: (period: DatePeriod) => void;
  additionalProps: any;
}) => {
  const { t } = useTranslation();
  const [activePeriod, setActivePeriod] = useState<DatePeriod>('today');

  const handleChangeDate = (currentPeriod: DatePeriod) => {
    switch (currentPeriod) {
      case 'today':
        setDate({ end: new Date(), start: dayjs().subtract(1, 'day').toDate() });
        break;
      case 'week':
        setDate({ end: new Date(), start: dayjs().subtract(7, 'day').toDate() });
        break;
      case 'month':
        setDate({ end: new Date(), start: dayjs().subtract(30, 'day').toDate() });
        break;
    }
  };

  const buttonProps = (currentPeriod: DatePeriod) => ({
    onClick: () => {
      setActivePeriod(currentPeriod);
      setPeriod(currentPeriod);
      handleChangeDate(currentPeriod);
    },
    className: activePeriod === currentPeriod ? 'Mui-selected' : ''
  });
  const handleChangeDateRange = (event: DateRangePickerChangeEvent) => {
    if (event.value && event.value.start !== null) {
      setDate({ start: event.value.start, end: event.value.end });
    }
  };

  const calendarSettings = {
    bottomView: 'month',
    topView: 'month',
    views: 2
  };

  return (
    <div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <InputLabel shrink={false} sx={{ marginBottom: commonFormStyles.labelMarginBottom }}>
          <LabelWrapper>
            <Typography sx={{ ...LabelStyle, fontSize: '14px' }}>
              {t('common:device_usage.filters.date_range_label', 'Date')}
            </Typography>
          </LabelWrapper>
        </InputLabel>
      </div>

      <StyledButtonGroup variant='outlined' aria-label='date range button group'>
        <StyledButton {...buttonProps('today')}>
          {t('common:device_usage.filters.date_range_today', 'Today')}
        </StyledButton>
        <StyledButton {...buttonProps('week')}>
          {t('common:device_usage.filters.date_range_week', 'Week')}
        </StyledButton>
        <StyledButton {...buttonProps('month')}>
          {t('common:device_usage.filters.date_range_month', 'Month')}
        </StyledButton>
        <StyledButton {...buttonProps('custom')}>
          {t('common:device_usage.filters.date_range_custom_date', 'Custom date')}
        </StyledButton>
      </StyledButtonGroup>

      {activePeriod === 'custom' && (
        <div>
          <DateRangePicker
            calendarSettings={calendarSettings}
            value={dateRange}
            format={'y-MM-dd'}
            onChange={handleChangeDateRange}
            {...additionalProps}
          />
        </div>
      )}
    </div>
  );
};

const detectCalendarView = (period: PeriodEnum): ActiveView => {
  switch (period) {
    case PeriodEnum.day:
      return 'month';
    case PeriodEnum.week:
      return 'month';
    case PeriodEnum.month:
      return 'year';
    default:
      return 'month';
  }
};

export const DatePickerFilter = ({
  date,
  period,
  setDate
}: {
  setDate: (date: Date) => void;
  period: PeriodEnum;
  date: Date;
}) => {
  const [calendarView, setCalendarView] = useState<ActiveView>(detectCalendarView(period));
  const handleChangeDate = (event: DatePickerChangeEvent) => {
    if (event.value) {
      setDate(event.value);
    }
  };

  useEffect(() => {
    setCalendarView(detectCalendarView(period));
  }, [period]);
  const CustomCalendar = (props: CalendarProps) => {
    return (
      <MultiViewCalendar
        defaultActiveView={calendarView}
        bottomView={calendarView}
        {...(props as MultiViewCalendarProps)}
        views={1}
      />
    );
  };
  return (
    <DatePicker
      label='Date range'
      calendar={CustomCalendar}
      value={date}
      format={'y-MM-dd'}
      onChange={handleChangeDate}
    />
  );
};

export const DatePickerRangeFilter = ({
  dateRange,
  period,
  setDate,
  additionalProps
}: {
  dateRange: DateRangeType;
  setDate: (dateRange: DateRangeType) => void;
  period: PeriodEnum;
  additionalProps: any;
}) => {
  const [calendarView, setCalendarView] = useState<ActiveView>(detectCalendarView(period));
  const handleChangeDate = (event: DateRangePickerChangeEvent) => {
    if (event.value && event.value.start !== null) {
      setDate({ start: event.value.start, end: event.value.end });
    }
  };

  useEffect(() => {
    setCalendarView(detectCalendarView(period));
  }, [period]);

  const calendarSettings = {
    bottomView: calendarView,
    topView: calendarView,
    views: 2
  };

  return (
    <div>
      <DateRangePicker
        calendarSettings={calendarSettings}
        value={dateRange}
        format={'y-MM-dd'}
        onChange={handleChangeDate}
        {...additionalProps}
      />
    </div>
  );
};

export const ExercisesFilter = ({
  setExercise,
  exercises,
  selectedExercise
}: {
  setExercise: (exercise: TransformedExercise) => void;
  exercises: TransformedExercise[];
  selectedExercise: TransformedExercise;
}) => {
  const handleExerciseChange = (exercise: TransformedExercise) => {
    setExercise(exercise);
  };

  return (
    <DropDownList
      style={{ width: '150px' }}
      data={exercises}
      onChange={(e) => handleExerciseChange(e.value)}
      value={selectedExercise}
      textField='name'
      label='Exercise'
    />
  );
};
