/* eslint-disable no-unused-vars */
import { useState } from 'react';
import DefaultLayout from 'adp-panel/layouts/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal from '../../components/Modals/ConfirmDeleteModal';
import Card from 'adp-panel/components/Card/Card';
import useUserData from '../../../hooks/useUserData';
import { userHasPermissions } from 'adp-panel/utils/permissionUtils';
import { RoleEnum } from 'adp-panel/api/users/users.types';
import { DeviceCode } from 'adp-panel/api/deviceCodes/deviceCodes.types';
import CustomTable, { ActionsWrapper } from 'adp-panel/components/Table/CustomTable';
import { ReactComponent as TrashSvg } from 'assets/trash-icon.svg';
import CustomButton from 'components/Button/CustomButton';
import {
  useCreateActivationCode,
  useDeleteActivationCode,
  useGetActivationCodes
} from 'adp-panel/hooks/api/useDeviceCodes';
import dayjs from 'dayjs';

const mapDeviceRows = (apiData: DeviceCode[]) =>
  apiData?.map((code) => ({
    id: code?.id,
    code: code?.code,
    used_by: code?.used_by,
    created_at: code?.created_at,
    used_at: code?.used_at,
    codeObject: code
  }));

const ActivationCodesList = () => {
  const { t } = useTranslation('devices');
  const { rolesByName } = useUserData();
  const [selectedCode, setSelectedCode] = useState<any>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10
  });
  const commonQueryParams = {
    perpage: 10,
    page: paginationModel.page + 1
  };
  const {
    result: activationCodes,
    total: totalActivationCodes,
    isLoading: isLoadingActivationCodes
  } = useGetActivationCodes(commonQueryParams);
  const { mutateAsync: deleteCode, isLoading: isLoadingDeleteCode } = useDeleteActivationCode();
  const { mutateAsync: createActivationCode, isLoading: isLoadingCreateActivationCode } =
    useCreateActivationCode();

  const isAdmin = userHasPermissions([RoleEnum.superAdmin], rolesByName);
  const {
    isOpen: isDeleteModalOpen,
    handleOpen: handleDeleteModalOpen,
    handleClose: handleDeleteModalClose
  } = useModal();
  const {
    isOpen: isCreateActivationCodeModalOpen,
    handleOpen: handleCreateActivationCodeModalOpen,
    handleClose: handleCreateActivationCodeModalClose
  } = useModal();

  const handleDeleteCode = async (codeObject: any) => {
    setSelectedCode(codeObject);
    handleDeleteModalOpen();
  };

  const createCode = async () => {
    await createActivationCode();
    handleCreateActivationCodeModalClose();
  };

  const deleteActivationCode = async () => {
    if (selectedCode) {
      await deleteCode(selectedCode?.id);
      handleDeleteModalClose();
    }
  };

  return (
    <DefaultLayout>
      <Card>
        {isCreateActivationCodeModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleCreateActivationCodeModalClose}
            handleAccept={createCode}
            isLoading={isLoadingCreateActivationCode}
            acceptMessage={t('codes:codes_list.table.create.accept_message', 'Yes, create')}
            title={t('codes:codes_list.table.create.title', 'Create activation code')}
            message={t(
              'codes:codes_list.table.create.message',
              'Do you want to create new activation code?'
            )}
          />
        )}
        {isDeleteModalOpen && selectedCode && (
          <ConfirmDeleteModal
            handleClose={handleDeleteModalClose}
            handleAccept={deleteActivationCode}
            isLoading={isLoadingDeleteCode}
            acceptMessage={t('codes:codes_list.table.delete.accept_message', 'Yes, deactivate')}
            title={t('codes:codes_list.table.delete.title', 'Deactivate code')}
            message={t('codes:codes_list.table.delete.message', {
              defaultValue: 'Do you want to deactivate code {{value}}?',
              value: selectedCode?.code
            })}
          />
        )}
        <div>
          <CustomTable
            actions={
              isAdmin && (
                <CustomButton onClick={handleCreateActivationCodeModalOpen}>
                  {t('codes:codes_list.table.add_new_code', 'Generate new activation code')}
                </CustomButton>
              )
            }
            tableData={activationCodes}
            tableHeader={t('codes:codes_list.table.header', 'Activation Codes List')}
            totalItems={totalActivationCodes}
            isLoading={isLoadingActivationCodes}
            columns={[
              {
                field: 'code',
                headerName: t('codes:codes_list.table.columns.code', 'Code'),
                flex: 1
              },
              {
                field: 'created_at',
                headerName: t('codes:codes_list.table.columns.created_at', 'Created at'),
                flex: 1,
                renderCell: (params) => (
                  <div>{dayjs(params.row.created_at).format('YYYY-MMM-DD HH:mm:ss')}</div>
                )
              },
              {
                field: 'used_at',
                headerName: t('codes:codes_list.table.columns.used_at', 'Used at'),
                flex: 1,
                renderCell: (params) => (
                  <div>
                    {params.row.used_at
                      ? dayjs(params.row.used_at).format('YYYY-MMM-DD HH:mm:ss')
                      : '-'}
                  </div>
                )
              },
              {
                field: 'used_by',
                headerName: t('codes:codes_list.table.columns.used_by', 'Used by'),
                flex: 1,
                renderCell: (params) => <div>{params.row.used_by ? params.row.used_by : '-'}</div>
              },
              {
                field: 'actions',
                width: 200,
                headerName: '',
                renderCell: (params) => {
                  const { id, codeObject } = params.row;

                  const actionButtons = [
                    <CustomButton
                      Icon={TrashSvg}
                      color='light'
                      data-testid='button-delete'
                      onClick={() => handleDeleteCode(codeObject)}
                    />
                  ];

                  return <ActionsWrapper>{actionButtons}</ActionsWrapper>;
                }
              }
            ]}
            mapTableData={mapDeviceRows}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </div>
      </Card>
    </DefaultLayout>
  );
};

export default ActivationCodesList;
