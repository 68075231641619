import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes, css } from 'styled-components';
import { ReactComponent as Undo } from 'assets/undo-solid.svg';
import { ReactComponent as Restore } from 'assets/restore.svg';
import { IconSidebarModes } from 'configurator/components/atoms/IconDescribed/IconDescribed';
import useWindowDimensions from 'configurator/hooks/useWindowDimensions';
import { getScrollbarWidth } from 'configurator/utils/funcs';
import useSidebar from 'configurator/hooks/useSidebar';
import { IconsContainer, StyledSaved } from './styled';
import CustomButton from 'components/Button/CustomButton';
import { Tooltip } from '@mui/material';
import { useMeetingStore } from 'configurator/reducers/meetingStore';
import { ViewModes } from 'configurator/utils/ViewModes/viewModes';
import { ReactComponent as EmgSvg } from 'assets/emg-icon.svg';
import { useUiStore } from 'configurator/reducers/uiStore';
import { MODALS } from 'configurator/views/Modals';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useDeviceInfoStore } from 'configurator/reducers/deviceInfoStore';
import useMeeting from 'configurator/hooks/useMeeting';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

type IconsSidebarProps = {
  undoFunc?: () => void;
  unsaved?: boolean;
  mode?: IconSidebarModes;
};

const IconsSidebar = ({
  undoFunc = () => true,
  unsaved = false,
  mode = IconSidebarModes.standard
}: IconsSidebarProps) => {
  const { t } = useTranslation('configurator');
  const ref = useRef<any>(null);
  const { width, height } = useWindowDimensions();
  const { handleSave, handleRestoreRecent, ticketCanBeSent, canShowSend } = useSidebar();
  const { viewMode, setItemMeeting, meetingStatus } = useMeetingStore((state) => ({
    viewMode: state.viewMode,
    setItemMeeting: state.setItemMeeting,
    meetingStatus: state.meetingStatus
  }));
  const { handleCloseMeeting, handleOpenMeeting } = useMeeting();
  const { openModal, videoSessionState } = useUiStore((state) => ({
    openModal: state.openModal,
    videoSessionState: state.videoSessionState
  }));
  const { amputeeId } = useDeviceInfoStore((state) => ({
    amputeeId: state.amputeeId
  }));
  const { enabled: remoteSessionEnabled } = useLiveConfiguratorStore();
  const { slotSelected } = useConfigStore((state) => state);

  useEffect(() => {
    const viewPort = document.querySelector('body')!;
    const viewPortHeight = viewPort.getBoundingClientRect().height;

    const refHeight = ref.current.getBoundingClientRect().height;

    ref.current.style.top = `${viewPortHeight / 2 - refHeight / 2}px`;
    ref.current.style.right = `${getScrollbarWidth() - 1}px`;
  }, [width, height]);

  const isEmgOn = viewMode === ViewModes.minimalEMG;

  const toggleEmg = () => {
    let set = ViewModes.minimalEMG;
    if (isEmgOn) {
      set = ViewModes.standard;
    }
    setItemMeeting('viewMode', set);
  };

  const toggleMeeting = async () => {
    if (!meetingStatus) {
      handleOpenMeeting(Number(amputeeId));
    } else {
      handleCloseMeeting();
    }
  };

  return (
    <IconsContainer mode={mode} ref={ref}>
      {canShowSend && ticketCanBeSent && (
        <Tooltip
          title={
            unsaved
              ? t('component.icons_sidebar.send_unsaved', 'Send (unsaved changes)')
              : t('component.icons_sidebar.send', 'Send')
          }
          placement='left'>
          <div>
            <CustomButton
              onClick={() => handleSave(unsaved)}
              Icon={StyledSaved}
              iconProps={{ unsaved, style: { width: '16px', height: '16px' } }}
              color='light'
            />
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={t('configurator:component.icons_sidebar.change_mode', 'Change mode')}
        placement='left'>
        <div>
          <CustomButton
            onClick={() => openModal(MODALS.changeMode)}
            color='light'
            iconProps={{ style: { width: '16px', height: '16px' } }}
            sx={{ padding: '0', width: '36px', height: '36px', margin: 0, minWidth: '36px' }}>
            M{slotSelected + 1}
          </CustomButton>
        </div>
      </Tooltip>
      <Tooltip
        title={t(
          'configurator:component.icons_sidebar.restore_recent_config',
          'Restore recent config'
        )}
        placement='left'>
        <div>
          <CustomButton
            onClick={handleRestoreRecent}
            Icon={Restore}
            color='light'
            iconProps={{ style: { width: '16px', height: '16px' } }}
          />
        </div>
      </Tooltip>
      {undoFunc && (
        <Tooltip title={t('configurator:component.icons_sidebar.undo', 'Undo')} placement='left'>
          <div data-tip data-for='undo'>
            <CustomButton
              onClick={undoFunc}
              Icon={Undo}
              iconProps={{ style: { width: '16px', height: '16px' } }}
              color='light'
            />
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={
          isEmgOn
            ? t('configurator:component.icons_sidebar.close_emg_view', 'Close side EMG view')
            : t('configurator:component.icons_sidebar.open_emg_view', 'Open side EMG view')
        }
        placement='left'>
        <div>
          <CustomButton
            onClick={toggleEmg}
            Icon={EmgSvg}
            color='light'
            iconProps={{ style: { width: '16px', height: '16px' } }}
          />
        </div>
      </Tooltip>
      {remoteSessionEnabled && (
        <Tooltip
          title={
            meetingStatus
              ? t('configurator:component.icons_sidebar.stop_meeting', 'Stop a meeting')
              : t('configurator:component.icons_sidebar.start_meeting', 'Start a meeting')
          }
          placement='left'>
          <div>
            <CustomButton
              onClick={toggleMeeting}
              Icon={meetingStatus ? VideocamOffIcon : VideocamIcon}
              color='light'
              loading={videoSessionState === FETCHING_STATES.loading}
              iconProps={{ style: { width: '16px', height: '16px' } }}
            />
          </div>
        </Tooltip>
      )}
    </IconsContainer>
  );
};

export default IconsSidebar;
