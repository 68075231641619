/* eslint-disable no-unreachable */
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import styled, { css } from 'styled-components';
import { Input } from '@progress/kendo-react-inputs';
import Card from '../../components/Card/Card';
import {
  customFrequencyValidator,
  customTotalGripValidator,
  requiredValidator
} from '../../components/FormInput/validators';
import { useEffect, useState } from 'react';
import { FormNumericTextBox } from '../../components/FormInput/FormNumericTextBox';
import { FormDatePicker } from '../../components/FormInput/FormDatePicker';
import GeneralLabel from 'adp-panel/components/Labels/GeneralLabel';
import dayjs from 'dayjs';
import {
  Grips,
  gripsGroupsOptionsMap,
  gripsIds,
  timePeriodsMap
} from 'adp-panel/utils/definesLocal';
import { FormDropDownList } from 'adp-panel/components/FormInput/FormDropdownList';
import FormCheckbox from 'adp-panel/components/FormInput/FormCheckbox';
import { useExercisesInfinite } from 'adp-panel/hooks/api/useGoals';
import { ExerciseEntryPayload, GoalFrequencyType } from 'adp-panel/api/goals/goals.types';
import { FormRadioGroup } from 'adp-panel/components/FormInput/FormRadioGroup';
import { gripsImagesMap } from 'adp-panel/utils/gripsImages';
import { frequencyPeriods } from './utils';
import { lowerCase } from 'lodash';
import { Error } from '@progress/kendo-react-labels';
import { RemoveButton } from 'adp-panel/components/ActionButton/ActionButton';
import FormButtonsWrapper from 'adp-panel/components/FormInput/FormButtonsWrapper';
import CustomButton from 'components/Button/CustomButton';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Step, StepLabel, Stepper } from '@mui/material';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StepsButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 16px;
`;

const GripGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 16px;
  align-items: start;
`;

const SetupButton = styled.div<{ active: boolean }>`
  background-color: ${({ active, theme }) => (active ? theme.palette.info : 'transparent')};
  width: 100%;
  padding: 8px;
  border: none;
  text-align: center;
`;

const InfoLabel = styled.span`
  color: ${({ theme }) => theme.palette.link2};
`;

const ExerciseGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Spacer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.separator};
  width: 100%;
`;

const SmallText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.label};
  color: ${({ theme }) => theme.palette.subtleText};
`;

interface CustomRadioButtonProps {
  isChecked?: boolean;
}

const CustomRadioButtonWrapper = styled.label<CustomRadioButtonProps>`
  border: 1px solid ${({ theme }) => theme.palette.border};
  border-radius: 4px;
  text-align: center;
  width: 100%;
  display: block;
  padding: 8px;
  cursor: pointer;

  ${({ isChecked }) =>
    isChecked &&
    css`
      border-color: ${({ theme }) => theme.palette.primary};
      background-color: ${({ theme }) => theme.palette.background};
    `};
`;

const usFormat = navigator.language === 'en-US';

const timePeriods = [
  { name: timePeriodsMap.get(GoalFrequencyType.d), id: GoalFrequencyType.d },
  { name: timePeriodsMap.get(GoalFrequencyType.w), id: GoalFrequencyType.w },
  { name: timePeriodsMap.get(GoalFrequencyType.m), id: GoalFrequencyType.m }
];

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);

const CustomRadioButton = (props: any) => {
  const isChecked = props.children.props.checked;

  return (
    <li>
      <CustomRadioButtonWrapper isChecked={isChecked}>{props.children}</CustomRadioButtonWrapper>
    </li>
  );
};

const startDateValidator = (value: any) => {
  const selectedDate = dayjs(value);

  const required = requiredValidator(value);

  if (required !== '') return required;

  if (selectedDate.isBefore(tomorrow, 'day')) {
    return 'Start date cannot be before the minimum date';
  }

  return '';
};

const TimeSetup = ({ formRenderProps, isView }: any) => {
  const endDate = dayjs(formRenderProps.valueGetter('start_date'))
    .add(formRenderProps.valueGetter('period') - 1, 'day')
    .format(usFormat ? 'MM/DD/YYYY' : 'DD/MM/YY');
  const startDate = formRenderProps.valueGetter('start_date');
  const period = formRenderProps.valueGetter('period');

  return (
    <>
      <Fieldset className={'k-form-fieldset'} style={{ margin: '0', justifySelf: 'start' }}>
        <legend>Set up time frame for goal</legend>
        <Field
          id={'start_date'}
          name={'start_date'}
          label={'Choose first day'}
          component={FormDatePicker}
          textField='start_date'
          key={`start_date`}
          format={usFormat ? 'MM/dd/yyyy' : 'dd/MM/yyyy'}
          min={tomorrow}
          validator={startDateValidator}
          disabled={isView}
        />
        <Field
          id={'period'}
          name={'period'}
          label={'How many days will this goal take?'}
          format={'n0'}
          component={FormNumericTextBox}
          textField='period'
          key={`period`}
          min={2}
          validator={requiredValidator}
          disabled={isView}
        />
      </Fieldset>
      {startDate && period && (
        <GeneralLabel
          style={{ marginTop: '16px', width: '100%' }}
          type='info'
          text={
            <>
              <span>Goal end date calculated from the date above</span>
              <br />
              <InfoLabel style={{ fontWeight: 700 }}>{endDate}</InfoLabel>
            </>
          }
        />
      )}
    </>
  );
};

const excludedGrips = [
  Grips.kGripCamera,
  Grips.kGripRestNopp,
  Grips.kGripRestOpp,
  Grips.kGripCounting
];

const gripsList = gripsIds
  .map((grip) => ({
    id: Number(grip),
    name: gripsGroupsOptionsMap.get(Number(grip))
  }))
  .filter((grip) => !excludedGrips.includes(grip.id));

const GripsSetup = ({ formRenderProps, slots, setSlots, isView }: any) => {
  const addNewSlot = () =>
    setSlots((prev: any) => {
      if (prev.length) return [...prev, prev[prev.length - 1] + 1];
      return [0];
    });
  const removeSlot = (slot: number) => {
    formRenderProps.onChange(`grip-${slot}`, {
      value: null
    });
    formRenderProps.onChange(`performedPerDay-${slot}`, {
      value: null
    });
    setSlots((prev: any) => prev.filter((item: any) => item !== slot));
  };

  const gripsSelected = slots.map(
    (slot: number) => formRenderProps.valueGetter(`grip-${slot}`)?.id
  );
  const gripsAvailable = gripsList.filter((grip) => !gripsSelected.includes(grip.id));
  const totalGripsMin: number =
    slots.reduce((acc: any, curr: any) => {
      const goalsPerformedValue = formRenderProps.valueGetter(`performedPerDay-${curr}`);
      if (goalsPerformedValue) acc += goalsPerformedValue;
      return acc;
    }, 0) ?? 0;

  return (
    <>
      <Fieldset className={'k-form-fieldset'} style={{ margin: '0', justifySelf: 'start' }}>
        <Field
          id={`grips-frequency`}
          name={`grips-frequency`}
          label={'Choose frequency'}
          component={FormRadioGroup}
          key={`grips-frequency`}
          data={frequencyPeriods}
          layout={'horizontal'}
          item={CustomRadioButton}
          validator={(value) =>
            customFrequencyValidator(value, formRenderProps.valueGetter(`period`))
          }
          style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))' }}
          disabled={isView}
        />
        <legend>How many grips should patient do?</legend>
        <Field
          id={`performedPerDay`}
          name={`performedPerDay`}
          label={'Total grips count'}
          component={FormNumericTextBox}
          validator={(value) => customTotalGripValidator(value, totalGripsMin)}
          key={`performedPerDay`}
          hint={"If you don't want to set goal for all grips, leave this field blank or type 0"}
          min={0}
          disabled={isView}
        />
        <>
          {slots.map((gripSlot: any) => (
            <div
              key={`group-${gripSlot}`}
              style={{ display: 'flex', alignItems: 'flex-end', gap: '8px' }}>
              {formRenderProps.valueGetter(`grip-${gripSlot}`)?.id !== undefined && (
                <img
                  style={{ width: '80px', height: '80px' }}
                  src={gripsImagesMap.get(formRenderProps.valueGetter(`grip-${gripSlot}`)?.id)}
                />
              )}
              <GripGroup>
                <Field
                  id={`grip-${gripSlot}`}
                  name={`grip-${gripSlot}`}
                  label={'Grip'}
                  component={FormDropDownList}
                  textField='name'
                  dataItemKey='id'
                  data={gripsAvailable}
                  key={`grip-${gripSlot}`}
                  validator={(value) => requiredValidator(value)}
                  disabled={isView}
                />
                <Field
                  id={`performedPerDay-${gripSlot}`}
                  name={`performedPerDay-${gripSlot}`}
                  label={`Grip count goal`}
                  component={FormNumericTextBox}
                  key={`performedPerDay-${gripSlot}`}
                  validator={requiredValidator}
                  min={1}
                  disabled={isView}
                />
                <RemoveButton
                  data-testid='delete-grip'
                  onClick={() => removeSlot(gripSlot)}
                  fillMode='outline'
                  icon='trash'
                  type='button'
                  style={{ position: 'relative', top: '48px', right: '0' }}
                  disabled={isView}
                />
              </GripGroup>
            </div>
          ))}
          <ButtonWrapper style={{ marginTop: '16px' }}>
            {!gripsSelected.includes(undefined) && (
              <Button onClick={addNewSlot} type='button' disabled={isView}>
                Add new grip
              </Button>
            )}
          </ButtonWrapper>
        </>
      </Fieldset>
    </>
  );
};

const SwitchesSetup = ({ formRenderProps, slots, setSlots, isView }: any) => {
  return (
    <>
      <Fieldset className={'k-form-fieldset'} style={{ margin: '0', justifySelf: 'start' }}>
        <legend>How many switches should patient do?</legend>
        <Field
          id={`switches-frequency`}
          name={`switches-frequency`}
          label={'Choose frequency'}
          component={FormRadioGroup}
          item={CustomRadioButton}
          key={`switches-frequency`}
          data={frequencyPeriods}
          layout={'horizontal'}
          validator={(value) =>
            customFrequencyValidator(value, formRenderProps.valueGetter(`period`))
          }
          style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))' }}
          disabled={isView}
        />
        <Field
          id={`switches`}
          name={`switches`}
          label={'Number of switches'}
          component={FormNumericTextBox}
          key={`switches`}
          validator={requiredValidator}
          min={1}
          disabled={isView}
        />
      </Fieldset>
    </>
  );
};

const searchKeyword = (term: string, search: string) => {
  term = lowerCase(term);
  search = lowerCase(search);
  if (search) {
    if (term.search(search) > -1) {
      return true;
    }
    return false;
  }
  return true;
};

const ExercisesSetup = ({
  formRenderProps,
  exercises,
  searchExerciseTerm,
  setSearchExerciseTerm,
  isView
}: {
  formRenderProps: any;
  exercises: ExerciseEntryPayload[];
  slots?: any;
  setSlots?: any;
  searchExerciseTerm: any;
  setSearchExerciseTerm: any;
  isView: boolean;
}) => {
  const exercisesStatuses = exercises.map((exercise) => ({
    ...exercise,
    status: formRenderProps.valueGetter(`exercise-status-${exercise.id}`),
    id: exercise.id
  }));

  const selectedExercises = exercisesStatuses.filter((exercise) => exercise.status);
  const unselectedExercises = exercisesStatuses.filter(
    (exercise) => !exercise.status && searchKeyword(exercise.name, searchExerciseTerm)
  );

  return (
    <>
      <Fieldset className={'k-form-fieldset'} style={{ margin: '0', justifySelf: 'start' }}>
        <legend>Choose exercises for this goal</legend>
        <Input
          placeholder={'Search'}
          type='text'
          onChange={(e) => setSearchExerciseTerm(e.target.value)}
          value={searchExerciseTerm}
          disabled={isView}
        />
        <GeneralLabel
          style={{ marginTop: '16px', marginBottom: '16px' }}
          type='info'
          text='If you need other habits write to Aether Biomedical'
        />
        {selectedExercises.map((exercise) => (
          <ExerciseGroup key={`exercises-${exercise.id}`}>
            <Field
              id={`exercise-status-${exercise.id}`}
              name={`exercise-status-${exercise.id}`}
              component={FormCheckbox}
              key={`exercise-status-${exercise.id}`}
              disabled={isView}
            />
            <div style={{ fontSize: '24px' }}>{exercise.icon}</div>
            <div style={{ flex: 1 }}>
              <div>{exercise.name}</div>
              <SmallText>{exercise.description}</SmallText>
              <GripGroup>
                <Field
                  id={`exercise-${exercise.id}`}
                  name={`exercise-${exercise.id}`}
                  label={'Set type'}
                  component={FormDropDownList}
                  textField='name'
                  dataItemKey='id'
                  data={timePeriods}
                  validator={(value) =>
                    customFrequencyValidator(value?.id, formRenderProps.valueGetter(`period`), true)
                  }
                  key={`exercise-${exercise.id}`}
                  disabled={isView}
                />
                <Field
                  id={`exerciseTimesPerformed-${exercise.id}`}
                  name={`exerciseTimesPerformed-${exercise.id}`}
                  label={'How many times'}
                  component={FormNumericTextBox}
                  key={`exerciseTimesPerformed-${exercise.id}`}
                  validator={requiredValidator}
                  min={1}
                  disabled={isView}
                />
              </GripGroup>
            </div>
          </ExerciseGroup>
        ))}
        {selectedExercises?.length > 0 && unselectedExercises?.length > 0 && (
          <Spacer style={{ marginBottom: '16px' }} />
        )}
        {!isView &&
          unselectedExercises.map((exercise) => (
            <ExerciseGroup key={`exercises-${exercise.id}`}>
              <Field
                id={`exercise-status-${exercise.id}`}
                name={`exercise-status-${exercise.id}`}
                component={FormCheckbox}
                key={`exercise-status-${exercise.id}`}
              />
              <div style={{ fontSize: '24px' }}>{exercise.icon}</div>
              <div style={{ flex: 1 }}>
                <div>{exercise.name}</div>
                <SmallText>{exercise.description}</SmallText>
              </div>
            </ExerciseGroup>
          ))}
      </Fieldset>
    </>
  );
};

const formSteps = {
  timeSetup: {
    component: TimeSetup,
    step: 0,
    label: 'Time frame'
  },
  gripsSetup: {
    component: GripsSetup,
    step: 1,
    label: 'Grips'
  },
  switchesSetup: {
    component: SwitchesSetup,
    step: 2,
    label: 'Switches'
  },
  exercisesSetup: {
    component: ExercisesSetup,
    step: 3,
    label: 'Exercises'
  }
};

const formStepsArray = Object.keys(formSteps).map((key) => ({
  ...formSteps[key]
}));

const createStepPages = (formSteps: any) => {
  const formsArray: any = [];
  for (const key in formSteps) {
    if (Object.prototype.hasOwnProperty.call(formSteps, key)) {
      const element = formSteps[key];
      formsArray.push(element);
    }
  }
  return formsArray
    .sort((formStepA: any, formStepB: any) => formStepA.step > formStepB.step)
    .map((formStep: any) => formStep.component);
};

const stepPages = createStepPages(formSteps);
const lastStepIndex = stepPages.length - 1;

const goalsFormValidator = (values: any, formStep: number) => {
  const gripSelected = Object.keys(values).some(
    (key) => key.startsWith('grip-') && values[key] !== null
  );
  const allGripsSelected = values['performedPerDay'];

  if (formStep === formSteps.gripsSetup.step) {
    if (!gripSelected && !allGripsSelected) {
      return {
        VALIDATION_SUMMARY: 'Error: At least one grip or number of any grips must be provided'
      };
    }
  }
};

const GoalsForm = ({
  isLoading,
  handleSubmit,
  isEdit = false,
  initialValues = null,
  isView = false,
  handleModalClose
}: any) => {
  const [formStep, setFormStep] = useState(0);
  const [formState, setFormState] = useState({});
  const [slots, setSlots] = useState<any>([0]);
  const { result: exercises } = useExercisesInfinite({
    queryParams: {
      perpage: 20
    }
  });

  const [searchExerciseTerm, setSearchExerciseTerm] = useState(null);
  const isLastStep = lastStepIndex === formStep;
  const { t } = useTranslation('goals');

  useEffect(() => {
    setFormState(initialValues);
    if (isEdit) {
      setSlots([...Array(initialValues.gripsCount).keys()]);
    }
  }, [isEdit]);

  const onStepSubmit = async (event: any) => {
    const { isValid, values } = event;
    if (!isValid && !isView) return;
    setFormStep(() => Math.min(formStep + 1, lastStepIndex));
    setFormState(values);

    if (isLastStep && isValid) {
      handleSubmit(values);
    }
  };

  const onPrevClick = (event: any) => {
    event.preventDefault();
    setFormStep(() => Math.max(formStep - 1, 0));
  };

  const handleStepperAction = (stepId) => {
    if (isView) return setFormStep(() => Math.min(stepId, lastStepIndex));

    if (stepId < formStep) return setFormStep(() => Math.max(stepId, 0));
    else return;
  };

  const canShowButton = () => {
    if (isView) return !isLastStep;

    return true;
  };

  return (
    <>
      <StyledCard>
        <Stepper alternativeLabel activeStep={formStep} sx={{ marginBottom: '25px' }}>
          {formStepsArray.map((step) => {
            return (
              <Step key={step.label} onClick={() => handleStepperAction(step.step)}>
                <StepLabel sx={{ '& .MuiStepLabel-label': { fontSize: '16px' } }}>
                  {step.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Form
          key={JSON.stringify(formState)}
          initialValues={formState}
          validator={(e) => goalsFormValidator(e, formStep)}
          onSubmitClick={(e) => onStepSubmit(e)}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <FieldsWrapper>
                {stepPages[formStep]({
                  formRenderProps,
                  slots,
                  setSlots,
                  exercises,
                  searchExerciseTerm,
                  setSearchExerciseTerm,
                  isView
                })}
              </FieldsWrapper>
              {formRenderProps.visited && formRenderProps.errors.VALIDATION_SUMMARY && (
                <Error style={{ marginTop: '16px', marginBottom: '16px' }}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </Error>
              )}
              <FormButtonsWrapper>
                <CustomButton type='button' color='light' onClick={() => handleModalClose()}>
                  Cancel
                </CustomButton>
                {formStep !== 0 ? (
                  <CustomButton onClick={(e) => onPrevClick(e)} type='button'>
                    Previous
                  </CustomButton>
                ) : null}
                {canShowButton() && (
                  <LoadingButton type='submit' loading={isLoading}>
                    {isLastStep ? 'Submit' : 'Next'}
                  </LoadingButton>
                )}
              </FormButtonsWrapper>
            </FormElement>
          )}
        />
      </StyledCard>
    </>
  );
};

export default GoalsForm;
