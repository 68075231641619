import {
  ACCOUNT,
  ACTIVATION_CODES,
  DASHBOARD,
  DEVICE,
  FEATURE_TOGGLES,
  MEDICAL_PROFESSIONALS,
  PATIENTS,
  RELEASES
} from '../../constants/routes';
import { RoleEnum } from '../api/users/users.types';
import { DEVICES_PERMISSIONS } from './rolesPermissions';
import { ReactComponent as DeviceSvg } from 'assets/device.svg';
import { ReactComponent as HomeSvg } from 'assets/dashboard.svg';
import { ReactComponent as MedicalSvg } from 'assets/medical.svg';
import { ReactComponent as CogSvg } from 'assets/cog_menu.svg';
import { ReactComponent as LogoutSvg } from 'assets/logout.svg';
import { ReactComponent as UsersSvg } from 'assets/users.svg';
import { ReactComponent as ActivationCodesSvg } from 'assets/activation_codes.svg';
import { ReactComponent as VersionMapping } from 'assets/version_mapping.svg';
import { ReactComponent as FeatureTools } from 'assets/feature_tools.svg';

export interface MenuItem {
  name: string;
  url?: string;
  disabled?: boolean;
  roles?: RoleEnum[];
  items?: MenuItem[];
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  action?: any;
}

export const MENU_ACTIONS = {
  logout: 'logout',
  inviteNewMedicalProfessional: 'inviteNewMedicalProfessional'
};

export const MENU_CONFIG: MenuItem[] = [
  {
    name: 'common:menu.dashboard',
    url: DASHBOARD,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.amputee,
      RoleEnum.clinician,
      RoleEnum.clinicianSupport,
      RoleEnum.clinicAdmin
    ],
    icon: HomeSvg
  },
  {
    name: 'common:menu.devices',
    url: DEVICE,
    roles: DEVICES_PERMISSIONS.LIST,
    icon: DeviceSvg
  },
  {
    name: 'common:menu.patients',
    url: PATIENTS,
    roles: [RoleEnum.superAdmin],
    icon: UsersSvg
  },
  {
    name: 'common:menu.medical_professionals',
    roles: [RoleEnum.superAdmin],
    icon: MedicalSvg,
    url: MEDICAL_PROFESSIONALS
  },
  {
    name: 'common:menu.activation_codes',
    roles: [RoleEnum.superAdmin],
    icon: ActivationCodesSvg,
    url: ACTIVATION_CODES
  },
  {
    name: 'common:menu.medical_professionals',
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin],
    icon: MedicalSvg,
    items: [
      {
        name: 'common:menu.medical_professionals_list',
        url: MEDICAL_PROFESSIONALS,
        roles: [RoleEnum.clinician, RoleEnum.clinicAdmin]
      },
      {
        name: 'common:menu.medical_professionals_new',
        action: MENU_ACTIONS.inviteNewMedicalProfessional,
        roles: [RoleEnum.clinician, RoleEnum.clinicAdmin]
      }
    ]
  },
  {
    name: 'Version mapping',
    url: RELEASES,
    roles: [RoleEnum.superAdmin],
    icon: VersionMapping
  },
  {
    name: 'Feature toggles',
    url: FEATURE_TOGGLES,
    roles: [RoleEnum.superAdmin],
    icon: FeatureTools
  }
];

export const MENU_BOTTOM_CONFIG: MenuItem[] = [
  {
    name: 'common:menu.account_settings',
    url: ACCOUNT,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.amputee,
      RoleEnum.clinician,
      RoleEnum.clinicianSupport,
      RoleEnum.clinicAdmin
    ],
    icon: CogSvg
  },
  {
    name: 'common:menu.logout',
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.amputee,
      RoleEnum.clinician,
      RoleEnum.clinicianSupport,
      RoleEnum.clinicAdmin
    ],
    icon: LogoutSvg,
    action: MENU_ACTIONS.logout
  }
];
