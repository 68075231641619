import { FormControl, Select } from '@mui/material';
import { CustomInputLabel } from './FormComboBox';

const CustomDropdown = ({
  id,
  label,
  value,
  onChange,
  options,
  disabled = false
}: {
  id: string;
  label: string;
  value: any;
  onChange: any;
  options: any;
  disabled?: boolean;
}) => {
  return (
    <FormControl sx={{ marginTop: '30px' }}>
      <CustomInputLabel
        id={id}
        label={label}
        style={{ position: 'relative', top: '-42px', left: '-14px' }}
      />
      <Select
        disabled={disabled}
        notched={false}
        value={value}
        labelId={`${id}-label`}
        id={id}
        size='small'
        onChange={(e) => onChange(e.target.value)}>
        {options}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
