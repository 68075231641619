/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { CompanyEntry } from 'configurator/api/company/company.types';
import { UserEntry } from 'configurator/api/users/users.types';
import { FirmwareVersionEntry, PCBVersionEntry } from 'configurator/api/versions/versions.types';
import { SortDirs } from 'configurator/utils/types';
import {
  ModeConfigTemplate,
  CommonConfigTemplate
} from 'configurator/consts/deviceConfig/deviceConfig.types';

export interface DeviceModel {
  id: number;
  name: string;
  type: string;
  orientation: string;
  active: number;
  created_at: string;
  updated_at: string;
}

export interface DeviceConfigEntry {
  id: number;
  deviceId: number;
  key: string;
  old_value: string;
  new_value: string;
  config: string;
  restore_point: number;
  changed_by: number;
  created_at: string;
  updated_at: string;
  author: UserEntry;
}

export interface DeviceConfigHistoryEntry {
  config: string;
  device_id: number;
  key: string;
  restore_point: number;
}

export interface DeviceEntry {
  id: number;
  serial: string;
  bluetooth_id: number;
  company_id: number;
  model_id: number;
  amputee_id: number;
  clinician_id: number;
  pcb_version_id: string;
  pcb_version: PCBVersionEntry;
  firmware_version_id: string;
  firmware_version?: FirmwareVersionEntry;
  active: number;
  created_at: string;
  updated_at: string;
  company?: CompanyEntry;
  model?: DeviceModel;
  amputee?: UserEntry;
  clinician?: UserEntry;
}

export interface DeviceSerialStatusEntry {
  status: boolean;
}

export interface DeviceHashesEntry {
  hash_global: string | null;
  hash_common: string | null;
  hash_mode1: string | null;
  hash_mode2: string | null;
  hash_mode3: string | null;
}

export interface DeviceUpdatePayload {
  serial?: string;
  bluetooth_id?: string;
  company_id?: number;
  model_id?: number;
  amputee_id?: number;
  clinician_id?: number;
  firmware_version_id?: number;
  pcb_version_id?: number;
  active?: 0 | 1;
}

export interface HashesUpdatePayload {
  hash_global?: string | null;
  hash_common?: string | null;
  hash_mode1?: string | null;
  hash_mode2?: string | null;
  hash_mode3?: string | null;
}

export enum DeviceExtendOptions {
  company = 'company',
  model = 'model',
  amputee = 'amputee',
  clinician = 'clinician',
  firmwareVersion = 'firmwareVersion',
  pcbVersion = 'pcbVersion'
}

export interface DeviceQueryParams {
  extend: DeviceExtendOptions[];
}

enum NoteTypes {
  private = 'private',
  public = 'public'
}

export interface ConfigEntry {
  created_at: Date;
  device_id: number;
  id: number;
  key: string;
  mode_id: number;
  updated_at: Date;
  value: string;
}

export interface ConfigHistoryEntry {
  config_entry: ConfigEntry;
  config_history_id: number;
  config_id: number;
  created_at: Date;
  id: number;
  new_value: string;
  old_value: string;
  updated_at: Date;
}

export interface ConfigHistoryItemEntry {
  author: UserEntry;
  changed_by: number;
  config: string;
  created_at: Date;
  device_id: number;
  entries: ConfigHistoryEntry[];
  id: number;
  index: number;
  name: string;
  restore_point: 0 | 1;
  updated_at: Date;
}

export interface ConfigNoteEntry {
  id: number;
  config_id: number;
  user_id: number;
  note: string;
  type: NoteTypes;
  created_at: string;
  updated_at: string;
  author: UserEntry;
}

export enum ConfigNotesSortOptions {
  date = 'date'
}

export enum ConfigNotesExtendOptions {
  user = 'user'
}

export interface ConfigNotesQueryParams {
  user?: number;
  type?: NoteTypes;
  extend?: ConfigNotesExtendOptions;
  perpage?: number;
  page?: number;
  sortby?: ConfigNotesSortOptions;
  sortdir?: SortDirs;
}

export enum ConfigNoteExtendOptions {
  author = 'author'
}

export interface ConfigNoteQueryParams {
  extend?: ConfigNoteExtendOptions;
}

export interface ConfigNoteBodyParams {
  note?: string;
  type?: NoteTypes;
}

export enum ConfigTemplatesScopeOptions {
  me = 'me',
  company = 'company',
  global = 'global'
}

export enum ConfigTemplatesExtendOptions {
  author = 'author',
  company = 'company'
}

export interface ConfigTemplatesQueryParams {
  search?: string;
  author?: number;
  company?: number;
  scope?: ConfigTemplatesScopeOptions;
  extend?: ConfigTemplatesExtendOptions;
  perpage?: number;
  page?: number;
}

export interface ConfigTemplateBodyParams {
  name: string;
  description?: string;
  owner?: ConfigTemplatesScopeOptions;
  author?: string;
  company?: string;
  config: any;
}

export interface AddDeviceCodePayload {
  code: string;
  serial: string;
}

export interface ConfigTemplateUpdateBodyParams {
  name?: string;
  description?: string;
  owner?: ConfigTemplatesScopeOptions;
  author?: string;
  company?: string;
  config?: any;
}

export interface ConfigTemplateQueryParams {
  extend?: ConfigTemplatesExtendOptions;
}

export interface TemplateNotesQueryParams {
  user?: number;
  extend?: ConfigNotesExtendOptions;
  perpage?: number;
  page?: number;
  sortby?: ConfigNotesSortOptions;
  sortdir?: SortDirs;
}

export interface TemplateNoteQueryParams {
  extend?: ConfigNoteExtendOptions;
}

export interface TemplateNoteBodyParams {
  note?: string;
}

export interface ModeEntry {
  id: number;
  config: string;
}

export interface DeviceConfigBodyParams {
  name?: string;
  common?: string;
  modes?: ModeEntry[];
}

export interface TestConfigBodyParams {
  description?: string;
  common?: string;
  modes?: ModeEntry[];
  p2p_session?: number | null;
}

export interface UpdateConfigDemoBodyParams {
  is_accepted?: 0 | 1;
  notes?: string;
}

export interface ConfigPayload {
  common: CommonConfigTemplate | [];
  modes: { config: ModeConfigTemplate | []; name: string; slot: number; id: number }[];
}

export interface ConfigPayloadObject {
  common: CommonConfigTemplate;
  modes: {
    _: number;
    [key: string]: { config: ModeConfigTemplate; name: string; slot: number; id: number } | number;
  };
}

export enum FirmwarePayloadFilterOptions {
  modes = 'modes',
  common = 'common'
}

export interface FirmwarePayloadQueryParams {
  filter?: FirmwarePayloadFilterOptions;
}

export interface DevicesQueryParams {
  extend?: DeviceExtendOptions[];
}
