import { DeviceEntry } from 'adp-panel/api/devices/device.types';
import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from 'components/Button/CustomButton';
import CustomAvatar from 'adp-panel/components/CustomAvatar/CustomAvatar';
import { ReactComponent as CogSvg } from 'assets/cog_configure.svg';
import { ReactComponent as ComputerSvg } from 'assets/computer.svg';
import { ReactComponent as SendSvg } from 'assets/send.svg';
import { MODALS } from 'configurator/views/Modals';
import { FETCHING_STATES } from 'configurator/consts/consts';
import { RoleEnum, UserEntry } from '../../../api/users/users.types';
import type { RolesByName } from '../types/patient.types';
import { userHasPermissions } from '../../../utils/permissionUtils';
import {
  HeaderContainer,
  ProfileSection,
  ProfileInfo,
  InfoBlock,
  ActionButtons,
  StyledName,
  StyledValue
} from '../styled/PatientHeader.styled';

interface PatientHeaderProps {
  patient: UserEntry;
  devices: DeviceEntry[];
  rolesByName: RolesByName;
  connected: boolean;
  serial: string | null;
  sessionStartingState: (typeof FETCHING_STATES)[keyof typeof FETCHING_STATES];
  handleConfigure: (devices: DeviceEntry[]) => void;
  handleOpenRemoteSession: (patientId: number) => void;
  openModal: (modal: string, modalProps: any) => void;
}

export const PatientHeader: React.FC<PatientHeaderProps> = ({
  patient,
  devices,
  rolesByName,
  connected,
  serial,
  sessionStartingState,
  handleConfigure,
  handleOpenRemoteSession,
  openModal
}) => {
  const { t } = useTranslation('patients');
  const deviceExists = devices?.[0]?.serial;
  const otherConnectionInProgress = connected && serial !== devices?.[0]?.serial;
  const isClinician = userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName);

  return (
    <HeaderContainer>
      <ProfileSection>
        <CustomAvatar name={patient?.name} big />
        <ProfileInfo>
          <StyledName variant='h6'>{patient?.name}</StyledName>
          <InfoBlock>
            <Typography variant='body2'>{t('patients:details.email_label', 'E-mail')}</Typography>
            <StyledValue>{patient?.email}</StyledValue>
          </InfoBlock>
          {devices?.[0]?.serial && (
            <InfoBlock>
              <Typography variant='body2'>
                {t('patients:details.device_id_label', 'Device SN')}
              </Typography>
              {devices.map((device) => (
                <StyledValue key={device.serial}>{device?.serial}</StyledValue>
              ))}
            </InfoBlock>
          )}
        </ProfileInfo>
      </ProfileSection>
      <ActionButtons>
        <Tooltip
          title={
            otherConnectionInProgress
              ? t(
                  'patients:details.start_local_session.other.tooltip',
                  'You are connected with other device. Disconnect first to switch.'
                )
              : ''
          }>
          <span>
            <CustomButton
              Icon={CogSvg}
              onClick={() => handleConfigure(devices)}
              disabled={!deviceExists || otherConnectionInProgress}>
              {t('patients:details.configure_zeus', 'Configure Zeus')}
            </CustomButton>
          </span>
        </Tooltip>
        <Tooltip
          title={
            otherConnectionInProgress
              ? t(
                  'patients:details.start_local_session.other.tooltip',
                  'You are connected with other device. Disconnect first to switch.'
                )
              : ''
          }>
          <span>
            <CustomButton
              Icon={ComputerSvg}
              color='light'
              loading={sessionStartingState === FETCHING_STATES.loading}
              onClick={() => handleOpenRemoteSession(Number(patient.id))}
              disabled={!deviceExists || otherConnectionInProgress}>
              {t('patients:details.start_remote_session', 'Start Remote Session')}
            </CustomButton>
          </span>
        </Tooltip>
        {isClinician && (
          <CustomButton
            Icon={SendSvg}
            color='light'
            onClick={() =>
              openModal(MODALS.inviteMedicalProfessional, { args: { patient: patient } })
            }>
            {t('patients:details.invite_medical_professional', 'Invite Medical Professional')}
          </CustomButton>
        )}
      </ActionButtons>
    </HeaderContainer>
  );
};
