import { Chip, ChipOwnProps } from '@mui/material';
import CustomButton from 'components/Button/CustomButton';
import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import CustomIcon, { icons } from 'components/CustomIcon/CustomIcon';
import {
  DescriptionPanel,
  DescriptionTitle,
  DescriptionText,
  InterpretationSection,
  SectionTitle,
  IssueContainer,
  InfoBox,
  InfoContent,
  InfoTitle,
  InfoText,
  TitleWithIcon,
  IconWrapper,
  ImageContainer,
  InfoTitleWithIcon,
  CloseButtonWrapper
} from './styled';
import GoodActiveTime from 'assets/graphs/good-active-time.svg';
import BadActiveTime from 'assets/graphs/bad-active-time.svg';

export type ChartType =
  | 'active-passive-time'
  | 'grip-count'
  | 'velocity'
  | 'grip-count-hour'
  | 'grip-switching'
  | 'time-spent'
  | 'grip-switching-hour'
  | 'grip-performed'
  | 'switches-performed'
  | 'exercises-performed'
  | 'activity-density';

interface GraphDescriptionProps {
  onClose: () => void;
  header: string;
  chartType: ChartType;
}

interface Issue {
  issue: {
    icon?: keyof typeof icons;
    iconVariant?: 'warning' | 'info' | 'success' | 'error';
    text: string;
  };
  significance: {
    icon?: keyof typeof icons;
    iconVariant?: 'warning' | 'info' | 'success' | 'error';
    text: string;
  };
  action: {
    icon?: keyof typeof icons;
    iconVariant?: 'warning' | 'info' | 'success' | 'error';
    text: string;
  };
  examples?: {
    good?: string;
    bad?: string;
  };
}

interface Section {
  title: string;
  icon?: keyof typeof icons;
  iconVariant?: 'warning' | 'info' | 'success' | 'error';
  issues: Issue[];
}

interface ChartInfo {
  description: string;
  sections: Section[];
  images?: {
    good?: string;
    bad?: string;
  };
}

const getChartDescription = (chartType: ChartType): ChartInfo => {
  switch (chartType) {
    case 'active-passive-time':
      return {
        description:
          'This graph shows the distribution between active and passive usage time of the prosthesis. Active time indicates periods of EMG signal detection, while passive time represents periods without signal activity.',
        sections: [
          {
            title: 'Active vs Passive Time',
            issues: [
              {
                issue: {
                  icon: 'alert-triangle',
                  iconVariant: 'warning',
                  text: 'Low active time ratio'
                },
                significance: {
                  icon: 'info-circle',
                  iconVariant: 'info',
                  text: 'May indicate limited engagement with the prosthesis'
                },
                action: {
                  icon: 'cursor-click',
                  iconVariant: 'success',
                  text: 'Consider reviewing usage patterns and potential barriers to active use'
                },
                examples: {
                  good: GoodActiveTime,
                  bad: BadActiveTime
                }
              }
            ]
          },
          {
            title: 'Empty Graph / Non-Collection of Data',
            issues: [
              {
                issue: {
                  icon: 'alert-triangle',
                  iconVariant: 'warning',
                  text: 'Low active time ratio'
                },
                significance: {
                  icon: 'info-circle',
                  iconVariant: 'info',
                  text: 'May indicate limited engagement with the prosthesis'
                },
                action: {
                  icon: 'cursor-click',
                  iconVariant: 'success',
                  text: 'Consider reviewing usage patterns and potential barriers to active use'
                },
                examples: {
                  good: GoodActiveTime,
                  bad: BadActiveTime
                }
              }
            ]
          }
        ]
      };

    case 'grip-count':
      return {
        description:
          'This graph displays the total number of grips performed, helping track the frequency and variety of grip patterns used.',
        sections: [
          {
            title: 'Grip Usage Patterns',
            icon: 'info-circle',
            issues: [
              {
                issue: {
                  icon: 'alert-triangle',
                  iconVariant: 'warning',
                  text: 'Low active time ratio'
                },
                significance: {
                  icon: 'info-circle',
                  iconVariant: 'info',
                  text: 'May indicate limited engagement with the prosthesis'
                },
                action: {
                  icon: 'cursor-click',
                  iconVariant: 'success',
                  text: 'Consider reviewing usage patterns and potential barriers to active use'
                },
                examples: {
                  good: GoodActiveTime,
                  bad: BadActiveTime
                }
              }
            ]
          }
        ]
      };

    // Add other chart types with their specific descriptions...
    default:
      return {
        description: 'This graph provides usage data for the bionic hand prosthesis.',
        sections: [
          {
            title: 'Data Interpretation',
            issues: [
              {
                issue: {
                  icon: 'alert-triangle',
                  iconVariant: 'warning',
                  text: 'Low active time ratio'
                },
                significance: {
                  icon: 'info-circle',
                  iconVariant: 'info',
                  text: 'May indicate limited engagement with the prosthesis'
                },
                action: {
                  icon: 'cursor-click',
                  iconVariant: 'success',
                  text: 'Consider reviewing usage patterns and potential barriers to active use'
                },
                examples: {
                  good: '/path/to/good-active-time.svg',
                  bad: '/path/to/bad-active-time.svg'
                }
              }
            ]
          }
        ]
      };
  }
};

interface CustomChip extends ChipOwnProps {
  sx?: any;
  status: 'good' | 'bad';
}

const CustomChip = ({ sx, ...props }: CustomChip & ComponentPropsWithoutRef<'div'>) => {
  const style = {
    ...(props.status === 'good' && {
      border: '1px solid #ABEFC6',
      backgroundColor: '#ECFDF3',
      color: '#067647'
    }),
    ...(props.status === 'bad' && {
      border: '1px solid #FECDCA',
      backgroundColor: '#FEF3F2',
      color: '#B42318'
    }),
    borderRadius: '16px',
    fontWeight: 500,
    fontSize: '12px',
    padding: '8px 2px',
    height: '22px',
    ...sx
  };

  return <Chip sx={{ ...style, ...sx }} {...props} />;
};

export const GraphDescription: React.FC<GraphDescriptionProps> = ({
  onClose,
  header,
  chartType
}) => {
  const { t } = useTranslation();
  const chartInfo = getChartDescription(chartType);

  return (
    <DescriptionPanel>
      <DescriptionTitle>{`${header} - Description`}</DescriptionTitle>
      <DescriptionText>{chartInfo.description}</DescriptionText>

      <SectionTitle>Interpretation and Possible Issues</SectionTitle>

      {chartInfo.sections.map((section, sectionIndex) => (
        <InterpretationSection key={sectionIndex}>
          <TitleWithIcon>
            {section.icon && (
              <IconWrapper variant={section.iconVariant}>
                <CustomIcon name={section.icon} />
              </IconWrapper>
            )}
            <SectionTitle>{section.title}</SectionTitle>
          </TitleWithIcon>

          {section.issues.map((issue, issueIndex) => (
            <React.Fragment key={issueIndex}>
              <IssueContainer>
                <InfoBox>
                  <InfoContent>
                    <InfoTitleWithIcon>
                      {issue.issue.icon && (
                        <IconWrapper variant={issue.issue.iconVariant}>
                          <CustomIcon name={issue.issue.icon} />
                        </IconWrapper>
                      )}
                      <InfoTitle>Issue</InfoTitle>
                    </InfoTitleWithIcon>
                    <InfoText>{issue.issue.text}</InfoText>
                  </InfoContent>
                </InfoBox>

                <InfoBox>
                  <InfoContent>
                    <InfoTitleWithIcon>
                      {issue.significance.icon && (
                        <IconWrapper variant={issue.significance.iconVariant}>
                          <CustomIcon name={issue.significance.icon} />
                        </IconWrapper>
                      )}
                      <InfoTitle>Significance</InfoTitle>
                    </InfoTitleWithIcon>
                    <InfoText>{issue.significance.text}</InfoText>
                  </InfoContent>
                </InfoBox>

                <InfoBox>
                  <InfoContent>
                    <InfoTitleWithIcon>
                      {issue.action.icon && (
                        <IconWrapper variant={issue.action.iconVariant}>
                          <CustomIcon name={issue.action.icon} />
                        </IconWrapper>
                      )}
                      <InfoTitle>Action</InfoTitle>
                    </InfoTitleWithIcon>
                    <InfoText>{issue.action.text}</InfoText>
                  </InfoContent>
                </InfoBox>

                {issue.examples && (
                  <ImageContainer>
                    {issue.examples.good && (
                      <div>
                        <InfoTitle>
                          <CustomChip label='Good' status='good' />
                        </InfoTitle>
                        <img src={issue.examples.good} alt='Good example' />
                      </div>
                    )}
                    {issue.examples.bad && (
                      <div>
                        <InfoTitle>
                          <CustomChip label='Bad' status='bad' />
                        </InfoTitle>
                        <img src={issue.examples.bad} alt='Bad example' />
                      </div>
                    )}
                  </ImageContainer>
                )}
              </IssueContainer>
            </React.Fragment>
          ))}
        </InterpretationSection>
      ))}

      <CloseButtonWrapper>
        <CustomButton color='light' onClick={onClose}>
          Close
        </CustomButton>
      </CloseButtonWrapper>
    </DescriptionPanel>
  );
};
