/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { Typography } from '@mui/material';
import { useUiStore } from 'configurator/reducers/uiStore';
import { useConfigStore } from 'configurator/reducers/configStore';
import { useLiveConfiguratorStore } from 'configurator/reducers/liveConfiguratorStore';
import { MODALS } from 'configurator/views/Modals';
import { SESSION_HISTORY } from 'constants/routes';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ButtonsWrapper, SessionText, SessionWrapper } from './RemoteSessionBar.styled';
import { ReactComponent as StopImg } from 'assets/stop.svg';
import CustomButton from 'components/Button/CustomButton';

const RemoteSessionBar = ({ ...props }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { config } = useConfigStore((state: any) => ({
    config: state.config,
    slotSelected: state.slotSelected
  }));
  const openModal = useUiStore((state: any) => state.openModal);
  const { remoteSessionEnabled, addSessionRestorePoint } = useLiveConfiguratorStore((state) => ({
    remoteSessionEnabled: state.enabled,
    addSessionRestorePoint: state.addSessionRestorePoint
  }));

  const handleSessionHistory = () => {
    navigate(SESSION_HISTORY);
  };

  const handleCloseSession = () => {
    openModal(MODALS.closeSession);
  };

  const handleCreateRestorePoint = () => {
    const modes = config.modes.map((mode) => ({ slot: mode.slot, config: mode.config }));
    addSessionRestorePoint({
      common: config.common.config,
      modes
    });
    toast.success('Restore point added');
  };

  if (!remoteSessionEnabled) {
    return <></>;
  }

  return (
    <SessionWrapper {...props}>
      <SessionText>
        <InfoOutlinedIcon />
        <Typography>{t('session.in_progress')}</Typography>
      </SessionText>
      <ButtonsWrapper>
        <CustomButton onClick={handleCreateRestorePoint} color='light'>
          {t('session.restore_point')}
        </CustomButton>
        <CustomButton onClick={handleSessionHistory} color='light'>
          {t('session.history')}
        </CustomButton>
        <CustomButton onClick={handleCloseSession} color='error' Icon={StopImg}>
          {t('session.stop')}
        </CustomButton>
      </ButtonsWrapper>
    </SessionWrapper>
  );
};

export default RemoteSessionBar;
